import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import { IronBody } from 'components/Layout';
import { useEntityContext } from 'contexts/EntityContext';

import EntityHistoryList from '../../EntityHistoryList/EntityHistoryList';
import HistoryLoader from '../../Loaders/HistoryLoader';

const EntityHistory = ({ entityKey, entityId }) => {
  const [loading, setLoading] = useState(false);
  const {
    getEntityState,
    actions: { fetchEntityHistories },
  } = useEntityContext();

  const entityState = getEntityState(entityKey, entityId);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await fetchEntityHistories(entityKey, entityId);
      } catch (error) {
        toast.error(error);
      }
      setLoading(false);
    };
    fetchData();
  }, [fetchEntityHistories, entityKey, entityId]);

  return (
    <IronBody>
      <div className="table-responsive">
        {!loading ? (
          <EntityHistoryList
            histories={entityState?.histories}
            schema={entityState?.schema}
          />
        ) : (
          <HistoryLoader />
        )}
      </div>
    </IronBody>
  );
};

EntityHistory.propTypes = {
  entityKey: PropTypes.string.isRequired,
  entityId: PropTypes.number.isRequired,
};

export default EntityHistory;
