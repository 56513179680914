import { createContext, useReducer, useContext, useCallback } from 'react';

import PropTypes from 'prop-types';

import reducer, { initialState, ActionTypes } from './state/DialogManagerState';

export const DialogManagerContext = createContext();

const useDialogManager = () => useContext(DialogManagerContext);

const DialogManagerContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getDialog = useCallback(
    id => {
      const dialogIndex = id
        ? state.dialogs.findIndex(item => item.id === id)
        : state.dialogs.length - 1;

      if (dialogIndex < 0) return undefined;

      return state.dialogs[dialogIndex];
    },
    [state],
  );

  const setDialogParam = useCallback(
    (value, id) => {
      const dialog = getDialog(id);
      dispatch({
        type: ActionTypes.SET_PARAM,
        payload: { id: dialog?.id, value },
      });
    },
    [dispatch, getDialog],
  );

  const openDialog = useCallback(params => {
    dispatch({ type: ActionTypes.OPEN_DIALOG, payload: params });
  }, []);

  const closeDialog = useCallback(
    async id => {
      const { params } = getDialog(id);
      const { beforeCloseCallback } = params;

      if (beforeCloseCallback && !(await beforeCloseCallback(params))) {
        return;
      }

      dispatch({ type: ActionTypes.CLOSE_DIALOG, payload: { id } });
    },
    [getDialog],
  );

  return (
    <DialogManagerContext.Provider
      value={{
        openDialog,
        closeDialog,
        getDialog,
        setDialogParam,
        state,
      }}
    >
      {children}
    </DialogManagerContext.Provider>
  );
};

DialogManagerContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export { useDialogManager };
export default DialogManagerContextProvider;
