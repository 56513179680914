import { Editor } from '@tinymce/tinymce-react';

import LayoutWithMenu from '../Layout/LayoutWithMenu';

const HtmlEditor = () => {
  const fields = [
    { key: 'firstName', label: 'First Name', value: 'Test' },
    { key: 'lastName', label: 'Last Name', value: 'Admin' },
    { key: 'abn', label: 'ABN', value: '123456789' },
    { key: 'companyName', label: 'Company Name', value: 'Jacsys' },
  ];

  const dragCallback = e => {
    e.dataTransfer.setData(
      'text/html',
      `<span class="dynamic-variable" contentEditable=false>@${e.target.textContent}</span>`,
    );
  };

  const handleEditorChange = (event, editor) => {
    let html = editor.getContent();

    // eslint-disable-next-line no-unused-expressions
    fields?.forEach(field => {
      html = html.split(`@${field.label}`).join(field.value);
    });

    document.getElementById('previewHtml').innerHTML = html;
  };

  return (
    <LayoutWithMenu>
      <br />
      ========= Fields =========
      {fields?.map(field => (
        <div
          key={field.key}
          id={field.key}
          draggable="true"
          onDragStart={dragCallback}
          style={{ border: 'solid 1px #CCC', padding: '5px', width: '300px' }}
        >
          {field.label}
        </div>
      ))}
      <br />
      <Editor
        className="Editor"
        apiKey="jb71mu3c2qv846cltedqwiw5scosvlv8btn8la6m8lpqvgip"
        init={{
          height: 500,
          forced_root_block: false,
          paste_data_images: true,
          // eslint-disable-next-line no-unused-vars
          images_upload_handler(blobInfo, success, failure) {
            const file = blobInfo.blob();
            const base64 = blobInfo.base64();

            success(`data:${file.type};base64,${base64}`);
          },
          // setup(editor) {
          //   editor.on('drop', e => {
          //     if (e.dataTransfer) {
          //       e.preventDefault();
          //       const data = e.dataTransfer.getData('text/html');
          //       editor.insertContent(data, false);
          //     }
          //   });
          // },
          content_css: '/htmlEditor.css',
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount',
          ],
          toolbar:
            'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | image | code',
        }}
        onSelectionChange={handleEditorChange}
      />
      <br />
      ========= Preview =========
      <div id="previewHtml" />
    </LayoutWithMenu>
  );
};

export default HtmlEditor;
