import { useContext, useEffect, useState, useCallback } from 'react';
import { Col, Row, Card, Button } from 'react-bootstrap';
import { BiChevronRight } from 'react-icons/bi';
import { FaFile, FaListUl, FaRegEdit, FaThLarge } from 'react-icons/fa';
import { Link, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import cx from 'classnames';
import * as PropTypes from 'prop-types';
import { getViewGroupBy } from 'utils/formUtils';
import { Messages } from 'utils/messages';

import LayoutWithMenu from 'components/Layout/LayoutWithMenu';
import MetaTags from 'components/MetaTags/MetaTags';
import { useDialogManager } from 'contexts/DialogManagerContext';
import { EntityContext } from 'contexts/EntityContext';

import EntityPageLoader from '../../components/Loaders/EntityPageLoader';
import { AppContext } from '../../contexts/AppContext';

const ENTITY_KEY_PRINTABLE_DOCUMENTS = 'printable_document';

const ReportCard = ({
  name,
  type,
  onEdit,
  onClickURL,
  smallDescription,
  className,
}) => {
  const TYPE_OPTIONS = {
    app: {
      bgColor: 'bg-warning-light',
      label: (
        <>
          <FaThLarge className="mr-1" color="var(--warning)" />
          Хянах самбар
        </>
      ),
    },
    list: {
      bgColor: 'bg-success-light',
      label: (
        <>
          <FaListUl className="mr-1" color="var(--success)" />
          Жагсаалт
        </>
      ),
    },
    document: {
      bgColor: 'bg-primary-extra-light',
      label: (
        <>
          <FaFile className="mr-1" color="var(--primary)" />
          Бичиг баримт
        </>
      ),
    },
  };
  const { bgColor, label } = TYPE_OPTIONS[type] || {};

  return (
    <Card className={cx('rounded-md flex-grow-1', bgColor, className)}>
      <Card.Body className="d-flex flex-column justify-content-between">
        <Button
          as={Link}
          to={onClickURL}
          variant="link"
          className="d-flex justify-content-between mb-3 w-100 px-0"
        >
          <h4 className="text-primary font-weight-bold text-left m-0">
            {name}
          </h4>
          <BiChevronRight size={30} color="var(--primary)" />
        </Button>
        <div>
          <div className="d-flex align-items-center">
            <span className="text-primary d-flex align-items-center">
              {label || `no types for ${type}`}
            </span>
            {onEdit && (
              <Button
                onClick={onEdit}
                variant="link"
                className="text-primary d-flex align-items-center"
              >
                <span className="mr-1">Засах</span>{' '}
                <FaRegEdit className="mt-n1" size={14} />
              </Button>
            )}
          </div>
          <small>{smallDescription}</small>
        </div>
      </Card.Body>
    </Card>
  );
};

ReportCard.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  onEdit: PropTypes.func,
  onClickURL: PropTypes.string,
  smallDescription: PropTypes.string,
};

ReportCard.defaultProps = {
  name: '',
  type: '',
  className: '',
  smallDescription: '',
  onEdit: undefined,
  onClickURL: '',
};

function SearchInput({ className, ...props }) {
  return (
    <div className="position-relative">
      <input
        {...props}
        type="search"
        className={cx('form-control pl-5', className)}
      />
      <span className="fa fa-search position-absolute top-0 left-0 h-100 d-flex flex-column justify-content-center px-3 pointer-event-none" />
    </div>
  );
}

SearchInput.propTypes = {
  ref: PropTypes.any,
  name: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};
SearchInput.defaultProps = {
  ref: undefined,
  value: undefined,
  name: undefined,
  className: undefined,
  placeholder: undefined,
  onChange: () => {},
};

const Report = () => {
  const {
    actions: { fetchAllByEntityName, resetEntityStates },
    getEntityState,
  } = useContext(EntityContext);

  const {
    state: { apps },
  } = useContext(AppContext);

  const pageLimit = 1000;
  const { appKey, id: entityId, operation } = useParams();

  const entityState = getEntityState(ENTITY_KEY_PRINTABLE_DOCUMENTS);
  const entitySchema = entityState?.schema;
  const [currentPage, setCurrentPage] = useState(0);
  const [keyword, setKeyword] = useState('');
  const history = useHistory();

  const { openDialog } = useDialogManager();

  const handleRefresh = () => {
    fetchAllByEntityName(
      ENTITY_KEY_PRINTABLE_DOCUMENTS,
      pageLimit,
      currentPage,
    );
  };

  const afterSaveCallback = useCallback(
    async ({ id }) => {
      toast.success(Messages.EntitySaved);

      fetchAllByEntityName(
        ENTITY_KEY_PRINTABLE_DOCUMENTS,
        pageLimit,
        currentPage,
      );

      resetEntityStates(ENTITY_KEY_PRINTABLE_DOCUMENTS, id);
    },
    [currentPage, fetchAllByEntityName, pageLimit, resetEntityStates],
  );

  const afterDeleteCallback = useCallback(
    async ({ id }) => {
      toast.success(Messages.EntityDeleted);

      fetchAllByEntityName(
        ENTITY_KEY_PRINTABLE_DOCUMENTS,
        pageLimit,
        currentPage,
      );

      resetEntityStates(ENTITY_KEY_PRINTABLE_DOCUMENTS, id);
    },
    [currentPage, fetchAllByEntityName, pageLimit, resetEntityStates],
  );

  const onEdit = report => {
    openDialog({
      entityKey: ENTITY_KEY_PRINTABLE_DOCUMENTS,
      entityId: report.id,
      afterSaveCallback,
      afterDeleteCallback,
    });
  };

  useEffect(() => {
    resetEntityStates(ENTITY_KEY_PRINTABLE_DOCUMENTS);

    const groupByFromView = getViewGroupBy(entityState?.view);

    fetchAllByEntityName(
      ENTITY_KEY_PRINTABLE_DOCUMENTS,
      pageLimit,
      0,
      undefined,
      undefined,
      undefined,
      groupByFromView,
    );
    setCurrentPage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (operation === 'edit' && entityId) {
      openDialog({
        entityKey: ENTITY_KEY_PRINTABLE_DOCUMENTS,
        entityId: parseInt(entityId, 10),
        afterSaveCallback,
        afterDeleteCallback,
      });
    } else if (operation === 'create') {
      openDialog({
        entityKey: ENTITY_KEY_PRINTABLE_DOCUMENTS,
        afterSaveCallback,
        afterDeleteCallback,
      });
    }
  }, [
    appKey,
    history,
    entityId,
    openDialog,
    operation,
    afterDeleteCallback,
    afterSaveCallback,
  ]);

  const getEntityURL = ({ entity_key }) => {
    let path;
    for (let i = 0; i < (apps || []).length; i += 1) {
      const app = apps[i];
      for (let j = 0; j < app.menu.length; j += 1) {
        const menu = app.menu[j];
        if (menu.entityKey === entity_key) {
          path = `/apps/${app.key}/${entity_key}`;
          break;
        }
        for (let l = 0; l < (menu.item_groups || []).length; l += 1) {
          const itemGroup = menu.item_groups[l];
          if (
            (itemGroup.items || []).some(item => item.entityKey === entity_key)
          ) {
            path = `/apps/${app.key}/${entity_key}`;
            break;
          }
        }
        if (path) {
          break;
        }
      }
      if (path) {
        break;
      }
    }
    return path;
  };

  const isLoading =
    !entitySchema || !entityState?.listData || entityState.isLoading;

  const filteredAppsWithDashboards = apps.filter(
    app =>
      app.dashboard &&
      app.dashboard.name.toLowerCase().indexOf(keyword.toLowerCase()) !== -1,
  );

  const filteredReports = (entityState?.listData || [])
    .map(report => ({
      ...report,
      entityURL: getEntityURL(report, apps),
    }))
    .filter(
      ({ name }) => name.toLowerCase().indexOf(keyword.toLowerCase()) !== -1,
    );

  return (
    <LayoutWithMenu>
      <div
        className={cx('px-3 px-lg-5 d-flex')}
        data-cy="entity-report-container"
      >
        {(isLoading && <EntityPageLoader className="w-100" />) || (
          <>
            {entitySchema?.name && (
              <MetaTags appKey="report" entityName={entitySchema.name} />
            )}

            <div className="w-100 d-flex flex-column">
              <Row
                data-cy="entity-table-wrapper"
                className={cx(
                  'mb-4 justify-content-between align-items-center',
                )}
              >
                <Col md="auto" className="d-flex align-items-center">
                  <h4 className="mb-4" data-cy="page-title">
                    Одоогоор хийгдсэн хянах самбар болон бичиг баримтын
                    загварууд
                  </h4>
                </Col>
                <Col md="auto" className="justify-content-end">
                  <div className="d-flex mt-3 mt-md-0">
                    <button
                      type="button"
                      className="btn btn-link text-primary border-0 font-weight-bolder mr-3 px-3"
                      data-cy="refresh"
                      onClick={handleRefresh}
                    >
                      <i className="fa fa-refresh" />
                    </button>
                    <SearchInput
                      placeholder="Хайх"
                      name="keyword"
                      value={keyword}
                      onChange={e => setKeyword(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>

              {!isLoading && entitySchema && entityState && (
                <Row>
                  {filteredAppsWithDashboards.map(app => (
                    <Col
                      md={4}
                      xl={3}
                      className="mb-3 d-flex flex-column"
                      key={app.id}
                    >
                      <ReportCard
                        smallDescription={app.key}
                        name={app?.dashboard?.name}
                        type="app"
                        onClickURL={`/apps/${app.key}`}
                        onEdit={() =>
                          history.push(`/apps/${app.key}?edit=true`)
                        }
                      />
                    </Col>
                  ))}
                  {filteredReports.map(report => (
                    <Col
                      md={4}
                      xl={3}
                      className="mb-3 d-flex flex-column"
                      key={report.id}
                    >
                      <ReportCard
                        smallDescription={report.entity_key}
                        name={report.name}
                        type={report.use_in_grid ? 'list' : 'document'}
                        onClickURL={report.entityURL}
                        onEdit={() => onEdit(report)}
                      />
                    </Col>
                  ))}
                </Row>
              )}
            </div>
          </>
        )}
      </div>
    </LayoutWithMenu>
  );
};

export default Report;
