import { useContext } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import AppMenu from '../components/AppMenu/AppMenu';
import LayoutWithMenu from '../components/Layout/LayoutWithMenu';
import { AppContext } from '../contexts/AppContext';

const Dashboard = () => {
  const { state } = useContext(AppContext);
  const { t } = useTranslation();
  return (
    <LayoutWithMenu>
      <Container className="Dashboard">
        <div className="mx-auto" style={{ maxWidth: '620px' }}>
          <h3 className="text-primary font-weight-bold mb-4">
            {t('main.apps')}
          </h3>
          <Row className="d-flex">
            {state.apps.map(app => (
              <Col md={4} key={app.key} className="mb-3">
                <AppMenu app={app} />
              </Col>
            ))}
          </Row>
        </div>
      </Container>
    </LayoutWithMenu>
  );
};

export default Dashboard;
