import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BsPlusCircle, BsPencilSquare, BsEye } from 'react-icons/bs';
import ReactSelect from 'react-select';

import cx from 'classnames';
import { post, get } from 'core/fetch';
import PropTypes from 'prop-types';
import { useDebounce } from 'use-debounce';
import { getPropValue } from 'utils/formUtils';

import { useDialogManager } from 'contexts/DialogManagerContext';

import dropDownStyle from '../../ComponentStyle/DropDownStyle';
import FieldLabel from './FieldLabel';

const parseVariables = (filterWhere, formData) => {
  if (!filterWhere) return null;

  if (!formData) return filterWhere;

  let query = JSON.stringify(filterWhere);

  const regexp = RegExp(/":[a-z0-9_]{2,}"/g);

  const variables = query.match(regexp);

  if (!variables?.length) return filterWhere;

  variables.forEach(item => {
    const key = item.substring(2, item.length - 1);
    const value = formData[key] || '';

    if (typeof value === 'string') {
      query = query.replace(item, `"${value}"`);
    } else {
      query = query.replace(item, value);
    }
  });

  return JSON.parse(query);
};

const SingleDropDown = ({
  name,
  options: propOptions,
  defaultValue,
  fieldItem,
  limit = 20,
  data,
}) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const { t } = useTranslation();

  const { control, setValue, errors, getValues } = useFormContext();
  const { getDialog, openDialog } = useDialogManager();

  const dialog = getDialog();
  const foreignKey = dialog?.params?.foreignKey;

  const componentOptionValue = fieldItem.ui_component_options?.value || 'id';
  const componentOptionLabel = fieldItem.ui_component_options?.label;

  const readOnly = fieldItem.ui_component_options?.readOnly || false;
  const placeholder = fieldItem?.placeholder;

  const filterWhere = parseVariables(
    fieldItem.relationshipOptions?.filter_where,
    data,
  );

  // Fetch to populate the dropdown with related entities
  const fetchRelatedOptions = async (searchText, fieldToQuery) => {
    if (!fieldItem.relationshipOptions) return;
    setLoading(true);

    try {
      const items = await post(
        `/${fieldItem.relationshipOptions.related_entity}/search?limit=${limit}`,
        {
          $where: {
            ...(filterWhere ?? {}),
            ...(searchText
              ? { [fieldToQuery]: { $like: `%${searchText}%` } }
              : {}),
            _is_deleted: false,
          },
        },
      );

      setOptions(
        items.map(item => ({
          value: item[componentOptionValue],
          label:
            getPropValue(componentOptionLabel, item) ||
            t('entity.filter.blank'),
        })),
      );

      setLoading(false);
    } catch (err) {
      throw new Error(err);
    }
  };

  const fetchSelectedOption = async (entity, value, setValueOptions) => {
    const response = await get(`/${entity}/${value}`);

    if (!response) return;

    setValue(
      name,
      {
        value: response[componentOptionValue],
        label: response[componentOptionLabel],
      },
      setValueOptions,
    );
  };

  useEffect(() => {
    if (propOptions) {
      setOptions(
        propOptions.map(prop => ({
          label: getPropValue(componentOptionLabel, prop),
          value: prop[componentOptionValue],
        })),
      );
    }

    if (foreignKey && foreignKey.fieldName === name) {
      fetchSelectedOption(
        fieldItem.relationshipOptions.related_entity,
        foreignKey.fieldValue,
        { shouldDirty: false },
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [inputText, setInputText] = useState();
  const [searchText] = useDebounce(inputText, 500);

  const transformedDefaultValue = defaultValue
    ? {
        value: defaultValue[componentOptionValue],
        label: defaultValue[componentOptionLabel],
      }
    : undefined;

  useEffect(() => {
    if (searchText || searchText === '') {
      fetchRelatedOptions(searchText, componentOptionLabel);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const handleInputChange = inputValue => {
    setInputText(inputValue);
  };

  const selectedValue = getValues(name);

  return (
    <FieldLabel
      fieldItem={fieldItem}
      actions={
        <div>
          {selectedValue?.value && (
            <button
              type="button"
              className="btn btn-link mr-1 btn-sm"
              data-cy="edit-related"
              onClick={() => {
                const entityKey = fieldItem.relationshipOptions?.related_entity;
                const entityId = parseInt(selectedValue.value, 10);

                openDialog({
                  entityKey,
                  entityId,
                  afterSaveCallback: () =>
                    fetchSelectedOption(entityKey, entityId),
                });
              }}
            >
              {readOnly && <BsEye title={t('entity.buttons.view-record')} />}
              {!readOnly && (
                <BsPencilSquare title={t('entity.buttons.edit-record')} />
              )}
            </button>
          )}
          {!readOnly && (
            <button
              type="button"
              data-cy="single-drop-down-create-new"
              className="btn btn-link btn-sm"
              onClick={() => {
                openDialog({
                  entityKey: fieldItem.relationshipOptions?.related_entity,
                });
              }}
            >
              <BsPlusCircle title={t('entity.buttons.create-record')} />
            </button>
          )}
        </div>
      }
    >
      <Controller
        as={ReactSelect}
        styles={{
          control: (provided, state) => ({
            ...provided,
            minHeight: dropDownStyle.minHeight,
            boxShadow: state.isFocused && dropDownStyle.focusedBoxShadow,
            borderColor: errors[name]
              ? dropDownStyle.errorBorderColor
              : (state.isFocused && dropDownStyle.focusedBorderColor) ||
                dropDownStyle.borderColor,
            '&:hover': {
              borderColor: dropDownStyle.hoverBorderColor,
            },
          }),
          singleValue: provided => ({
            ...provided,
            ...dropDownStyle.textStyle,
          }),
          placeholder: provided => ({
            ...provided,
            ...dropDownStyle.textStyle,
          }),
          option: (provided, state) => ({
            ...provided,
            ...dropDownStyle.textStyle,
            color: state.isSelected
              ? dropDownStyle.selectedColor
              : dropDownStyle.color,
          }),
          menuPortal: base => ({ ...base, zIndex: 9999 }),
        }}
        theme={theme => ({
          ...theme,
          colors: {
            ...theme.colors,
            ...dropDownStyle.themeColors,
          },
        })}
        isDisabled={foreignKey?.fieldName === name || readOnly}
        onInputChange={handleInputChange}
        isClearable
        onMenuOpen={() => {
          if (!inputText) {
            fetchRelatedOptions();
          }
        }}
        className={cx('single-dropdown', {
          'is-invalid': errors[name],
        })}
        menuPortalTarget={document.body}
        placeholder={placeholder}
        noOptionsMessage={() =>
          loading ? t('main.loading') : t('entity.control.no-option')
        }
        control={control}
        options={options}
        defaultValue={transformedDefaultValue}
        name={name}
        id={name}
      />

      {fieldItem.description && (
        <small className="form-text text-muted">{fieldItem.description}</small>
      )}
    </FieldLabel>
  );
};

SingleDropDown.propTypes = {
  value: PropTypes.any,
  options: PropTypes.array,
  name: PropTypes.string.isRequired,
  limit: PropTypes.number,
  defaultValue: PropTypes.any,
  fieldItem: PropTypes.shape({
    placeholder: PropTypes.string,
    description: PropTypes.string,
    ui_component_options: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      readOnly: PropTypes.bool,
    }),
    relationshipOptions: PropTypes.shape({
      related_entity: PropTypes.string.isRequired,
      filter_where: PropTypes.object,
    }),
  }).isRequired,
  data: PropTypes.any,
};

SingleDropDown.defaultProps = {
  value: undefined,
  options: [],
  limit: 30,
  defaultValue: undefined,
  data: {},
};

export default SingleDropDown;
