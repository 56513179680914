import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { VscClose } from 'react-icons/vsc';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { FILTER_CRITERIAS } from 'constants/filterCriterias';

import {
  DateRangePickerField,
  DefaultField,
  MultiDropDown,
  NumericField,
  WorkflowStepField,
} from './FilterFields';
import FilterPopover from './FilterPopover';

import s from './AppliedFilter.module.scss';

const AppliedFilter = ({
  values,
  filterKey,
  fieldItem,
  workflowDefinition,
  removeAppliedFilter,
  handleSubmit,
}) => {
  const { register, errors } = useFormContext();
  const uiComponent = fieldItem.ui_component;
  const { label } = fieldItem;
  const { t } = useTranslation();

  // Setting the default criteria, because some fields have their own structure
  // FILTER_CRITERIAS values
  const criteriaStructure =
    FILTER_CRITERIAS[uiComponent] || FILTER_CRITERIAS.default;
  const defaultCrieria = criteriaStructure[0].value;

  const name = `${filterKey}.value`;
  const fieldItemLabel = fieldItem?.label;

  const fieldComponent = {
    WorkflowStep: (
      <WorkflowStepField
        steps={workflowDefinition?.steps}
        labelForFinalised={workflowDefinition?.outcome?.success.label}
        labelForRejected={workflowDefinition?.outcome?.failure.label}
        name={name}
      />
    ),
    MultiDropDown: (
      <MultiDropDown
        name={name}
        uiComponentOptions={fieldItem.ui_component_options}
        relationshipOptions={fieldItem.relationshipOptions}
      />
    ),
    SingleDropDown: (
      <MultiDropDown
        name={name}
        uiComponentOptions={fieldItem.ui_component_options}
        relationshipOptions={fieldItem.relationshipOptions}
      />
    ),
    Checkbox: (
      <MultiDropDown
        name={name}
        options={[
          {
            label: fieldItemLabel,
            value: true,
          },
          {
            label: `Not ${fieldItemLabel}`,
            value: false,
          },
        ]}
        uiComponentOptions={fieldItem.ui_component_options}
      />
    ),
    DateField: (
      <DateRangePickerField
        name={name}
        uiComponentOptions={fieldItem.ui_component_options}
      />
    ),
    NumericField: <NumericField name={name} />,
    default: (
      <DefaultField
        name={name}
        uiComponentOptions={fieldItem.ui_component_options}
      />
    ),
  };

  return (
    <div
      className={cx(s.root, {
        [s.isInvalid]: errors[filterKey],
      })}
    >
      <FilterPopover
        className={s.appliedFilterDropdown}
        id={`applied-filter-field-${filterKey}`}
        onApply={handleSubmit}
        title={
          <button
            type="button"
            className={cx('btn btn-sm btn-light', s.appliedFilter)}
            style={{ borderRight: 0 }}
          >
            <label className={s.label}>{label}:</label>{' '}
            {values || t('entity.filter.none-selected')}
          </button>
        }
      >
        <div className="form-group mb-0">
          {/* <label htmlFor={name}>{fieldItemLabel}</label> */}
          {fieldComponent[uiComponent] || fieldComponent.default}
        </div>

        <input
          type="hidden"
          ref={register}
          name={`${filterKey}.operator`}
          value={defaultCrieria}
        />

        <input
          type="hidden"
          ref={register}
          name={`${filterKey}.uiComponent`}
          value={uiComponent}
        />
      </FilterPopover>

      <button
        type="button"
        className={cx('btn btn-sm btn-light', s.removeAppliedFilter)}
        style={{ backgroundColor: 'white', borderLeft: 0, color: 'black' }}
        onClick={() => removeAppliedFilter(filterKey)}
        data-cy="remove-applied-filter"
      >
        <VscClose />
      </button>
    </div>
  );
};

AppliedFilter.propTypes = {
  values: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  filterKey: PropTypes.string.isRequired,
  fieldItem: PropTypes.shape({
    label: PropTypes.string,
    ui_component: PropTypes.string,
    ui_component_options: PropTypes.object,
    relationshipOptions: PropTypes.object,
  }).isRequired,
  workflowDefinition: PropTypes.oneOfType([PropTypes.bool, PropTypes.object])
    .isRequired,
  removeAppliedFilter: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

AppliedFilter.defaultProps = {
  values: '',
};

export default AppliedFilter;
