import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';

import PropTypes from 'prop-types';
import './Paginate.scss';

const Paginate = ({ pageLimit, total, currentPage, onPageChanged }) => {
  const [pageSize, setPageSize] = useState(pageLimit);
  const pageSizeList = [15, 30, 70, 100];
  const pageCount = Math.ceil(total / pageLimit);
  const limit = Math.ceil(10000 / pageLimit);
  const shouldBlock = pageCount > limit;
  const start = (currentPage + 1) * pageLimit - (pageLimit - 1);
  const end = Math.min(start + pageLimit - 1, total);
  const { t } = useTranslation();

  const handlePageClick = async data => {
    onPageChanged({ number: data.selected, limit: pageSize });
  };

  const handlePageSizeChange = async event => {
    const value = parseInt(event.target.value, 20);
    setPageSize(value);
    onPageChanged({ number: 0, limit: value });
  };

  return (
    <div className="paginate">
      <div className="d-flex">
        <div className="p-2">
          <select
            className="form-control form-control-sm"
            value={pageSize}
            onChange={handlePageSizeChange}
          >
            {pageSizeList.map(item => (
              <option value={item} key={`item${item}`}>
                {item}
              </option>
            ))}
          </select>
        </div>
        <div className="py-3 flex-fill">{t('entity.grid.per-page')}</div>
        <div className="py-3">
          {t('entity.grid.page-showing')} {start}-{end}{' '}
          {t('entity.grid.page-of')} {total}
        </div>
        <div className="p-2 flex-shrink-1 align-self-center">
          <ReactPaginate
            forcePage={currentPage}
            pageCount={shouldBlock ? limit : pageCount}
            onPageChange={handlePageClick}
            previousLabel={<i className="las la-chevron-left" />}
            nextLabel={<i className="las la-chevron-right" />}
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            marginPagesDisplayed={5}
            pageRangeDisplayed={5}
            containerClassName="pagination"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            activeClassName="active"
          />
          {shouldBlock && (
            <div>
              <small>
                * Number of pages have been intentionally limited to {limit}
                &nbsp;of&nbsp;
                {pageCount}. Use filters to restrict your criteria before
                paging.
              </small>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Paginate.propTypes = {
  pageLimit: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChanged: PropTypes.func.isRequired,
};

export default Paginate;
