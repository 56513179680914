import PropTypes from 'prop-types';

import EntityTable from 'components/Entity/EntityTable/EntityTable';
import Paginate from 'components/Paginate/Paginate';
import ENTITY from 'constants/Entity';
import { useDialogManager } from 'contexts/DialogManagerContext';

import KanbanView from './KanbanView/KanbanView';
import MapView from './MapView/MapView';
import TimelineView from './TimelineView/TimelineView';

const EntityViews = ({
  selectedView,
  entityKey,
  schema,
  listData,
  pageLimit,
  currentPage,
  total,

  // Functions
  handlePage,
  handleSorting,
  afterSaveCallback,
  afterDeleteCallback,
}) => {
  const { openDialog } = useDialogManager();

  if (selectedView.viewType === ENTITY.VIEWS.LIST) {
    return (
      <>
        {listData && schema && (
          <EntityTable
            schema={schema}
            data={listData}
            onRowSelected={event => {
              if (!event.node.selected) return;

              openDialog({
                entityKey,
                entityId: event.node.data.id,
                afterSaveCallback,
                afterDeleteCallback,
              });

              // Unselect row selected, so I can re-select the same again
              event.node.setSelected(false);
            }}
            onColumnSorted={handleSorting}
          />
        )}

        {total > 0 && (
          <Paginate
            pageLimit={pageLimit}
            total={total}
            currentPage={currentPage}
            onPageChanged={handlePage}
          />
        )}
      </>
    );
  }

  if (selectedView.viewType === ENTITY.VIEWS.MAP && listData && schema) {
    return (
      <MapView
        data={listData}
        options={selectedView.viewOptions}
        entityKey={entityKey}
        fields={schema.fields}
        afterSaveCallback={afterSaveCallback}
        afterDeleteCallback={afterDeleteCallback}
      />
    );
  }

  if (selectedView.viewType === ENTITY.VIEWS.KANBAN && listData) {
    return (
      <KanbanView
        data={listData}
        options={selectedView.viewOptions}
        schema={schema}
        onCardClick={cardId => {
          openDialog({
            entityKey,
            entityId: parseInt(cardId, 10),
            afterSaveCallback,
            afterDeleteCallback,
          });
        }}
      />
    );
  }

  if (selectedView.viewType === ENTITY.VIEWS.TIMELINE) {
    return (
      <TimelineView
        data={listData}
        options={selectedView.viewOptions}
        onItemClick={itemId => {
          openDialog({
            entityKey,
            entityId: parseInt(itemId, 10),
            afterSaveCallback,
            afterDeleteCallback,
          });
        }}
      />
    );
  }

  return <span>Selected view doesn't exist.</span>;
};

EntityViews.propTypes = {
  selectedView: PropTypes.object.isRequired,
  entityKey: PropTypes.string.isRequired,
  schema: PropTypes.object.isRequired,
  listData: PropTypes.array,
  pageLimit: PropTypes.number.isRequired,
  currentPage: PropTypes.number,
  total: PropTypes.number,
  handlePage: PropTypes.func.isRequired,
  handleSorting: PropTypes.func.isRequired,
  afterSaveCallback: PropTypes.func.isRequired,
  afterDeleteCallback: PropTypes.func.isRequired,
};

EntityViews.defaultProps = {
  listData: [],
  total: 0,
  currentPage: 0,
};

export default EntityViews;
