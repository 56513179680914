import ContentLoader from 'react-content-loader';

import PropTypes from 'prop-types';

const AttachmentLoader = ({ rows, radius, ...props }) => {
  const attachments = [];
  const OFFSET = 73;
  for (let i = 0; i < rows; i += 1) {
    attachments.push(
      <rect
        rx={radius}
        ry={radius}
        key={1 + i * 4}
        y={19 + i * OFFSET}
        width="36"
        height="53"
      />,
      <rect
        rx={radius}
        ry={radius}
        key={2 + i * 4}
        x="48"
        y={21 + i * OFFSET}
        width="221"
        height="17"
      />,
      <rect
        rx={radius}
        ry={radius}
        key={3 + i * 4}
        x="48"
        y={51 + i * OFFSET}
        width="221"
        height="17"
      />,
    );
  }
  const height = OFFSET * rows;
  return (
    <ContentLoader viewBox={`0 0 270 ${height}`} height={height} {...props}>
      {attachments}
    </ContentLoader>
  );
};
AttachmentLoader.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  rows: PropTypes.number,
  radius: PropTypes.number,
};

AttachmentLoader.defaultProps = {
  width: 270,
  radius: 3,
  rows: 3,
};

export default AttachmentLoader;
