import { useEffect, useContext, createRef } from 'react';
import DatePicker from 'react-datepicker';
import { useFormContext, Controller } from 'react-hook-form';

import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { normaliseFormat } from 'utils/dateUtils';

import { AppContext } from 'contexts/AppContext';

import FieldLabel from './FieldLabel';

const DatePickerField = ({ name, defaultValue, fieldItem, ...props }) => {
  const { errors, control, setValue } = useFormContext();
  const {
    state: { settings },
  } = useContext(AppContext);

  const placeholder = fieldItem?.placeholder;
  const dateInputRef = createRef();

  const { time } = fieldItem.ui_component_options;
  const readOnly = fieldItem.ui_component_options?.readOnly || false;

  useEffect(() => {
    const date = moment.utc(defaultValue);
    if (date.isValid()) {
      setValue(name, date.toDate());
    }
  }, [setValue, defaultValue, name]);

  return (
    <FieldLabel fieldItem={fieldItem}>
      <div className="date-picker-container">
        <Controller
          control={control}
          name={name}
          defaultValue={
            defaultValue ? moment.utc(defaultValue).toDate() : undefined
          }
          render={({ onChange, onBlur, value: fieldValue }) => {
            const selected = moment(fieldValue).isValid()
              ? fieldValue
              : undefined;

            return (
              <DatePicker
                onChange={onChange}
                onBlur={onBlur}
                selected={selected}
                className={cx('form-control', {
                  'is-invalid': errors[name],
                })}
                dateFormat={normaliseFormat(
                  settings.dateFormat,
                  time && settings.timeFormat,
                  { moment: false },
                )}
                readOnly={readOnly}
                timeFormat={settings.timeFormat}
                todayButton="Today"
                showTimeInput={time}
                placeholderText={placeholder}
                autoComplete="off"
                ref={dateInputRef}
                name={name}
                {...props}
              />
            );
          }}
        />

        {fieldItem.description && (
          <small className="form-text text-muted">
            {fieldItem.description}
          </small>
        )}
        <span
          role="button"
          className="form-inline d-flex justify-content-center align-items-center date-picker-icon"
          onClick={() => dateInputRef.current.setFocus()}
          onKeyPress={e => {
            if (e.keyCode === 13) dateInputRef.current.setFocus();
          }}
          tabIndex={0}
        >
          <i className="far fa-calendar-alt" />
        </span>
      </div>
    </FieldLabel>
  );
};

DatePickerField.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.any,
  fieldItem: PropTypes.shape({
    placeholder: PropTypes.string,
    description: PropTypes.string,
    ui_component_options: PropTypes.any,
  }).isRequired,
};

DatePickerField.defaultProps = {
  defaultValue: null,
};

export default DatePickerField;
