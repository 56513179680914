import { useState } from 'react';

import './ToggleButton.scss';
import PropTypes from 'prop-types';

const ToggleButton = ({ onChange, value }) => {
  const [toggle, setToggle] = useState(value);

  const onClick = () => {
    setToggle(toggle === 'active' ? '' : 'active');
    onChange();
  };

  return (
    <button
      type="button"
      className={`toggle-mixin btn btn-sm toggle-color ${toggle}`}
      data-toggle="button"
      aria-pressed="false"
      onClick={onClick}
    >
      <div className="handle" />
    </button>
  );
};

ToggleButton.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
};

ToggleButton.defaultProps = {
  onChange: () => {},
  value: '',
};

export default ToggleButton;
