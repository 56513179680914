import { useState, useCallback, useMemo, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import cx from 'classnames';
import PropTypes from 'prop-types';

import SchemaCard from 'components/Schema/SchemaCard/SchemaCard';

import s from './SchemaList.module.scss';

const SchemaList = ({ entityTypes }) => {
  const history = useHistory();
  const [keyword, setKeyword] = useState('');
  const { t } = useTranslation();

  const handleActivation = useCallback(
    event => {
      if (event.key === 'Escape') {
        setKeyword('');
      }
    },
    [setKeyword],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleActivation);

    return () => {
      window.removeEventListener('keydown', handleActivation);
    };
  }, [handleActivation]);

  const filteredItems = useMemo(() => {
    if (entityTypes.length === 0) return [];

    return entityTypes.filter(
      ({ name }) => name.toLowerCase().indexOf(keyword.toLowerCase()) !== -1,
    );
  }, [entityTypes, keyword]);

  return (
    <Container className={s.root}>
      <Row className="mb-4">
        <Col>
          <h1 className="mb-4">{t('settings.entities')}</h1>
        </Col>

        <Col>
          <div className="form-group has-search">
            <span className={cx('fa fa-search', s.keywordIcon)} />

            <input
              type="search"
              className={cx('form-control', s.keyword)}
              placeholder={t('settings.search-entities')}
              name="keyword"
              value={keyword}
              onChange={e => setKeyword(e.target.value)}
            />
          </div>
        </Col>
      </Row>

      <Row>
        {filteredItems.map(entityType => (
          <Col md={3} key={entityType.key}>
            <SchemaCard
              schema={entityType}
              onClick={() => {
                history.push(`/settings/schemas/${entityType.key}`);
              }}
            />
          </Col>
        ))}

        {filteredItems.length === 0 && (
          <Col>{t('settings.no-entities-found')}</Col>
        )}
      </Row>
    </Container>
  );
};

SchemaList.propTypes = {
  entityTypes: PropTypes.array,
};

SchemaList.defaultProps = {
  entityTypes: [],
};

export default SchemaList;
