import { useState, useEffect, useContext } from 'react';

import PropTypes from 'prop-types';
import buildRelatedGridOptions from 'utils/buildRelatedGridOptions';

import { AgGrid } from 'components/ThirdLibraries';
import { AppContext } from 'contexts/AppContext';

import s from './EntityTable.module.scss';

const EntityTable = ({
  schema,
  data: entityData,
  onRowSelected,
  onColumnSorted,
}) => {
  const [gridApi, setGridApi] = useState(null);

  const {
    state: { settings },
  } = useContext(AppContext);

  const onGridReady = api => {
    setGridApi(api);
  };

  const onSortChanged = params => {
    if (params.api) {
      const sortModel = params.api.getSortModel();
      let sort;
      if (sortModel.length > 0) {
        sort = {};
        sort[sortModel[0].colId] = sortModel[0].sort;
      }

      // TODO: the server-side sort is not working properly.
      // The function is calling fetchAllByEntityName which is
      // resetting the state, re-rendering the agGrid and not saving the
      // previous sort value.
      onColumnSorted(sort);
    }
  };

  useEffect(() => {
    if (gridApi) {
      gridApi.setRowData(entityData);
    }
  }, [entityData, gridApi]);

  const agGridOptions = schema.gridSettings?.agGrid;
  if (!agGridOptions) {
    return <span>Ачааллаж байна...</span>;
  }

  const {
    columnDefs,
    defaultColDef,
    suppressHorizontalScroll,
    frameworkComponents,
  } = buildRelatedGridOptions(agGridOptions, schema, settings);

  return (
    <AgGrid
      getRowClass={({ node }) => {
        switch (node.data?._workflow_outcome) {
          case 2:
            return 'border-left border-left-danger bg-danger-light';
          case 1:
            return 'border-left border-left-success bg-success-light';
          default:
            return 'border-left';
        }
      }}
      rowData={entityData || []}
      className={s.grid}
      columnDefs={columnDefs}
      onGridReady={onGridReady}
      onCellClicked={onRowSelected}
      onSortChanged={onSortChanged}
      getRowNodeId={data => data.id}
      rowStyle={{
        background: 'white',
        // height: '5.25rem',
        // lineHeight: '3.25rem',
        // borderColor: 'rgba(85, 52, 230, 0.2)',
      }}
      defaultColDef={defaultColDef}
      immutableData
      suppressHorizontalScroll={suppressHorizontalScroll}
      frameworkComponents={frameworkComponents}
    />
  );
};

EntityTable.propTypes = {
  schema: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  onRowSelected: PropTypes.func.isRequired,
  onColumnSorted: PropTypes.func.isRequired,
};

export default EntityTable;
