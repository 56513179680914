import { useContext, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import './CognitoLogin.scss';
import { BsKeyFill, BsKey } from 'react-icons/bs';
import { MdOutlineAlternateEmail, MdOutlinePassword } from 'react-icons/md';

import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
} from 'amazon-cognito-identity-js';

import ErrorAlert from 'components/ErrorAlert/ErrorAlert';
import { useAuthContext } from 'contexts/AuthContext';

import { AppContext } from '../../../contexts/AppContext';

const WindowTypes = {
  Login: 1,
  Reset: 2,
};

const CognitoLogin = () => {
  const { register, handleSubmit } = useForm();
  const {
    state: { settings },
  } = useContext(AppContext);
  const [windowType, setWindowType] = useState(WindowTypes.Login);
  const [error, setError] = useState();
  const [cognitoSession, setCognitoSession] = useState();

  const { t } = useTranslation();

  const {
    actions: { login },
    state,
  } = useAuthContext();

  const getCognitoUser = email => {
    const userPool = new CognitoUserPool({
      UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    });

    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    return cognitoUser;
  };
  const onSubmit = async (data, event) => {
    event.preventDefault();
    const { username, password } = data;
    const authenticationDetails = new AuthenticationDetails({
      Username: username,
      Password: password,
    });

    const cognitoUser = getCognitoUser(username);
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (session, userConfirmationNecessary) => {
        if (userConfirmationNecessary) {
          // eslint-disable-next-line no-console
          console.log(
            '---userConfirmationNecessary:',
            userConfirmationNecessary,
          );
        }
        login({
          id_token: session.getIdToken().getJwtToken(),
          access_token: session.getAccessToken().getJwtToken(),
        });
      },
      onFailure: err => setError(err.message),
      newPasswordRequired() {
        setWindowType(WindowTypes.Reset);
        setCognitoSession(cognitoUser);
      },
    });
  };

  const onResetSubmit = async (data, event) => {
    event.preventDefault();
    const { password1, password2 } = data;
    if (password1 !== password2) {
      setError('Давтан оруулсан нууц үг ялгаатай байна!');
      return;
    }

    if (!cognitoSession) {
      setError('Алдаа гарлаа дахин орлдоно уу!');
      return;
    }

    cognitoSession.completeNewPasswordChallenge(
      password1,
      {},
      {
        onSuccess: user => {
          login({
            id_token: user.getIdToken().getJwtToken(),
            access_token: user.getAccessToken().getJwtToken(),
          });
        },
        onFailure: err => {
          setError(err.message);
        },
      },
    );
  };

  return (
    <div
      style={{
        backgroundImage: `url(${settings.loginBackgroundImage})`,
      }}
      className="Login d-flex align-items-center justify-content-center flex-column flex-grow-1 m-0 w-100"
    >
      {windowType === WindowTypes.Login && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card className="login-card">
            <Card.Body className="p-5">
              <img
                className="company-logo"
                src={settings.companyLogo}
                alt={settings.name}
              />
              <h4 className="my-3">{settings.name}</h4>

              <div className="input-group form-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <MdOutlineAlternateEmail />
                  </span>
                </div>
                <input
                  type="text"
                  id="username"
                  name="username"
                  className="form-control"
                  placeholder="email"
                  ref={register({ required: true })}
                />
              </div>
              <div className="input-group form-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <MdOutlinePassword />
                  </span>
                </div>
                <input
                  type="password"
                  id="password"
                  name="password"
                  className="form-control"
                  placeholder="password"
                  ref={register({ required: true })}
                />
              </div>
              <div>{state?.error && <ErrorAlert error={state?.error} />}</div>
              <div>{error && <ErrorAlert error={error} />}</div>
              <div className="px-4">
                <button type="submit" className="btn btn-block btn-primary">
                  {t('main.signin')}
                </button>
              </div>
              <hr className="my-4" />
              <p className="m-0 letter-spacig-lg">
                <a
                  className="text-body"
                  href="https://www.jacsys.mn"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {t('main.copyright')}
                </a>
              </p>
            </Card.Body>
          </Card>
        </form>
      )}

      {windowType === WindowTypes.Reset && (
        <form onSubmit={handleSubmit(onResetSubmit)}>
          <Card className="login-card">
            <Card.Body className="p-5">
              <img
                className="company-logo"
                src={settings.companyLogo}
                alt={settings.name}
              />
              <h4 className="my-3">Нууц үг шинэчлэх</h4>

              <div className="input-group form-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <BsKey />
                  </span>
                </div>
                <input
                  type="password"
                  id="password1"
                  name="password1"
                  className="form-control"
                  placeholder="New password"
                  ref={register({ required: true })}
                />
              </div>
              <div className="input-group form-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <BsKeyFill />
                  </span>
                </div>
                <input
                  type="password"
                  id="password2"
                  name="password2"
                  className="form-control"
                  placeholder="New password"
                  ref={register({ required: true })}
                />
              </div>
              <div>{state?.error && <ErrorAlert error={state?.error} />}</div>
              <div>{error && <ErrorAlert error={error} />}</div>
              <div className="px-4">
                <button type="submit" className="btn btn-block btn-primary">
                  {t('main.update')}
                </button>
              </div>
              <hr className="my-4" />
              <p className="m-0 letter-spacig-lg">
                <a
                  className="text-body"
                  href="https://www.jacsys.mn"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {t('main.copyright')}
                </a>
              </p>
            </Card.Body>
          </Card>
        </form>
      )}
    </div>
  );
};

export default CognitoLogin;
