import { useContext } from 'react';

import GoogleMapReact from 'google-map-react';
import isValidCoordinates from 'is-valid-coordinates';
import { isArray } from 'lodash';
import PropTypes from 'prop-types';

import { AppContext } from 'contexts/AppContext';

import TextField from './TextField';

const parseLocation = (coordinates, asString) => {
  if (!coordinates) return undefined;

  const isMSSQL = isArray(coordinates.points) && coordinates.points.length;

  const location = isMSSQL
    ? { lat: coordinates.points[0].x, lng: coordinates.points[0].y }
    : { lat: coordinates.x, lng: coordinates.y };

  if (!isValidCoordinates(location.lng, location.lat)) {
    return undefined;
  }

  if (asString && location) {
    return isMSSQL
      ? `${location.lat},${location.log}`
      : `${location.lng},${location.lat}`;
  }

  return location;
};

const GoogleMaps = ({ coordinates, googleMapsApiKey }) => {
  const location = parseLocation(coordinates);
  return (
    <GoogleMapReact
      yesIWantToUseGoogleMapApiInternals
      bootstrapURLKeys={{
        key: googleMapsApiKey,
      }}
      defaultCenter={location}
      defaultZoom={15}
      options={maps => ({
        gestureHandling: 'cooperative',
        mapTypeControl: true,
        mapTypeControlOptions: {
          style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
          position: maps.ControlPosition.TOP_LEFT,
          mapTypeIds: [maps.MapTypeId.ROADMAP, maps.MapTypeId.SATELLITE],
        },
      })}
      onGoogleApiLoaded={({ map, maps }) => {
        if (!map || !maps) {
          console.error(
            'Google Maps returned null. Possible invalid center latLong',
            map,
            maps,
          );
          return;
        }

        if (location) {
          const infowindow = new maps.InfoWindow({
            content: `<div><b>Location</b><br><br>Lat: ${location.lat}<br>Long: ${location.lng}</div>`,
          });
          const marker = new maps.Marker({
            position: location,
            map,
          });
          marker.addListener('click', () => {
            infowindow.open(map, marker);
          });
          infowindow.open(map, marker);
        }
      }}
    />
  );
};

GoogleMaps.propTypes = {
  coordinates: PropTypes.any,
  googleMapsApiKey: PropTypes.string.isRequired,
};

GoogleMaps.defaultProps = {
  coordinates: undefined,
};

const MapLocation = props => {
  const { state } = useContext(AppContext);

  const normalisedValue = {
    ...props,
    ...{ defaultValue: parseLocation(props.defaultValue, true) },
  };

  return (
    <>
      <TextField {...normalisedValue} />
      {state.creds?.google?.googleMapsApiKey && (
        <div style={{ height: '300px', width: '100%' }}>
          <GoogleMaps
            googleMapsApiKey={state.creds.google.googleMapsApiKey}
            coordinates={props.defaultValue || undefined}
          />
        </div>
      )}
    </>
  );
};

MapLocation.propTypes = {
  defaultValue: PropTypes.any,
};

MapLocation.defaultProps = {
  defaultValue: undefined,
};

export default MapLocation;
