import { Row } from 'react-bootstrap';

import ButtonLoader from './ButtonLoader';
import TableLoader from './TableLoader';

const EntityPageLoader = props => (
  <div {...props}>
    <Row className="mx-0 justify-content-between mb-5">
      <ButtonLoader width={200} />
      <div className="d-flex">
        <ButtonLoader width={140} className="mr-4" />
        <ButtonLoader width={140} />
      </div>
    </Row>
    <TableLoader />
  </div>
);

export default EntityPageLoader;
