import { memo } from 'react';
import Board from 'react-trello';

import { flatten } from 'lodash';
import PropTypes from 'prop-types';
import { getPropValue } from 'utils/formUtils';

// eslint-disable-next-line no-unused-vars
const KanbanView = ({ data, options, schema, onCardClick }) => {
  // this component doesn't accept css-module
  const styles = {
    minHeight: '350px',
    height: 'calc(100vh - 340px)',
    backgroundColor: 'var(--light)',
  };

  const doneColumnKey = '#done';
  const lanes = [
    ...schema.workflow.definition.steps,
    { key: doneColumnKey, name: 'Дууссан', outcome: true },
  ];

  const doneItems = flatten(data.map(item => item.results)).filter(
    item => item._workflow_outcome,
  );

  const items = {
    lanes: lanes.map(item => {
      const laneItems =
        item.key === doneColumnKey
          ? doneItems
          : data
              .filter(
                record =>
                  parseInt(record._workflow_current_step, 10) ===
                  parseInt(item.order, 10),
              )
              .reduce(
                (current, record) => [
                  ...current,
                  ...record.results.filter(result => !result._workflow_outcome),
                ],
                [],
              );

      return {
        id: item.key,
        title: item.name,
        cards: laneItems.map(record => {
          const style =
            record._workflow_outcome === 1
              ? { backgroundColor: 'var(--success-light)' }
              : { backgroundColor: 'var(--danger-light)' };

          return {
            id: record.id.toString(),
            style: item.key === doneColumnKey ? style : {},
            title: `#${record.id} - ${options.title
              .map(prop => getPropValue(prop, record))
              .join(' - ')}`,
            description: options.description
              .map(prop => getPropValue(prop, record))
              .join(' - '),
            // label: '30 mins',
            draggable: false,
          };
        }),
      };
    }),
  };

  return (
    <Board
      data={items}
      style={styles}
      onCardClick={onCardClick}
      hideCardDeleteIcon
    />
  );
};

KanbanView.propTypes = {
  data: PropTypes.array.isRequired,
  schema: PropTypes.object.isRequired,
  options: PropTypes.shape({
    title: PropTypes.array.isRequired,
    description: PropTypes.array.isRequired,
    groupBy: PropTypes.shape({
      sourceType: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  onCardClick: PropTypes.func.isRequired,
};

KanbanView.defaultProps = {};

export default memo(KanbanView);
