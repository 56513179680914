import { createContext, useReducer, useContext } from 'react';

import { post } from 'core/fetch';
import PropTypes from 'prop-types';

import AuthActionTypes from './state/AuthActionTypes';
import reducer, { initialState } from './state/AuthState';

export const AuthContext = createContext();

const useAuthContext = () => useContext(AuthContext);

const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  /*
   * ACTIONS
   */
  const logout = async () => {
    dispatch({ type: AuthActionTypes.Logout });

    try {
      await post(`/auth/logout`, {});
      dispatch({ type: AuthActionTypes.LogoutSuccess });
      window.location.href = '/';
    } catch (e) {
      dispatch({ type: AuthActionTypes.LogoutFail });
    }
  };

  const login = async formData => {
    dispatch({ type: AuthActionTypes.Login });
    return post(`/auth/email/login`, formData)
      .then(response => {
        dispatch({ type: AuthActionTypes.LoginSuccess });
        window.location.href = `/`;
        return response;
      })
      .catch(e => {
        dispatch({ type: AuthActionTypes.LoginFail, error: e.error });
        return e;
      });
  };

  const reset = async formData => {
    dispatch({ type: AuthActionTypes.ResetPassword });
    return post(`/auth/email/reset`, formData)
      .then(response => {
        dispatch({ type: AuthActionTypes.ResetPasswordSuccess });
        return response;
      })
      .catch(e => {
        dispatch({ type: AuthActionTypes.ResetPasswordFail, error: e.error });
        return e;
      });
  };

  const actions = { logout, login, reset };

  return (
    <AuthContext.Provider
      value={{
        actions,
        state,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export { useAuthContext };
export default AuthContextProvider;
