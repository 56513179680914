import { Helmet } from 'react-helmet';

import PropTypes from 'prop-types';

const MetaTags = ({ entityName }) => (
  <Helmet>
    <title>{entityName} | Tsum</title>
  </Helmet>
);

MetaTags.propTypes = {
  entityName: PropTypes.string.isRequired,
};

export default MetaTags;
