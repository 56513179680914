import { useState, useEffect } from 'react';
import { Tab, Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import cx from 'classnames';
import { FormMode } from 'core/formMode';
import PropTypes from 'prop-types';

import { useEntityContext } from 'contexts/EntityContext';

import EntityActionBar from '../EntityActionBar/EntityActionBar';
import EntityAttachment from '../EntityAttachment/EntityAttachment';
import EntityComment from '../EntityComment/EntityComment';
import EntityForm from '../EntityForm/EntityForm';
import EntityHistory from '../EntityHistory/EntityHistory';

import s from './EntityMain.module.scss';

const EntityMain = ({ entityId, entityKey }) => {
  const [actionBarRef, setActionBarRef] = useState(null);
  const { actions, getEntityState } = useEntityContext();
  const { t } = useTranslation();

  const entityState = getEntityState(entityKey, entityId);

  useEffect(() => {
    if (entityId || !entityState?.schema)
      actions.fetchOneByType(entityKey, entityId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createOrEditMode = entityId ? FormMode.edit : FormMode.create;
  const formMode = entityState?.formData?._workflow_outcome
    ? FormMode.readonly
    : createOrEditMode;

  return (
    <>
      {/* <EntityActionBar onRefLoaded={el => setActionBarRef(el)} /> */}
      <Tab.Container id="entity-tabs" defaultActiveKey="details">
        <div className="entity-tab">
          <Nav variant="tabs" className={cx('pr-4', s.tab)}>
            {formMode !== FormMode.create && (
              <>
                <Nav.Item>
                  <Nav.Link
                    className="m-0"
                    eventKey="attachments"
                    data-cy="tab-attachments"
                  >
                    {t('entity.main.attachments')}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className="m-0"
                    eventKey="history"
                    data-cy="tab-history"
                  >
                    {t('entity.main.history')}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className="m-0"
                    eventKey="comment"
                    data-cy="tab-comments"
                  >
                    {t('entity.main.comments')}
                  </Nav.Link>
                </Nav.Item>
              </>
            )}
            <Nav.Item>
              <Nav.Link className="m-0" eventKey="details">
                {t('entity.main.details')}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="flex-fill">
              <EntityActionBar onRefLoaded={el => setActionBarRef(el)} />
            </Nav.Item>
          </Nav>
        </div>
        <div className="entity-tab-content">
          <Tab.Content variant="pills" className={s.tabContent}>
            <Tab.Pane eventKey="details">
              <EntityForm
                entityState={entityState}
                formMode={formMode}
                entityActionBarRef={actionBarRef}
                entityKey={entityKey}
                entityId={entityId}
              />
            </Tab.Pane>
            {formMode !== FormMode.create && (
              <>
                <Tab.Pane eventKey="attachments" mountOnEnter>
                  <EntityAttachment entityKey={entityKey} entityId={entityId} />
                </Tab.Pane>
                <Tab.Pane eventKey="history" mountOnEnter unmountOnExit>
                  <EntityHistory
                    entityKey={entityKey}
                    entityId={entityId}
                    schema={entityState?.schema}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="comment" mountOnEnter unmountOnExit>
                  <EntityComment entityKey={entityKey} entityId={entityId} />
                </Tab.Pane>
              </>
            )}
          </Tab.Content>
        </div>
      </Tab.Container>
    </>
  );
};

EntityMain.propTypes = {
  entityId: PropTypes.number,
  entityKey: PropTypes.string.isRequired,
};

EntityMain.defaultProps = {
  entityId: undefined,
};

export default EntityMain;
