import { FaChevronRight } from 'react-icons/fa';

import cx from 'classnames';
import PropTypes from 'prop-types';

import './SchemaCard.scss';

const SchemaCard = ({ schema, onClick }) => (
  <div className="SchemaCard">
    <div
      role="button"
      key={schema.key}
      className={cx('entity-card', { 'is-workflow': schema.has_workflow })}
      onClick={() => {
        onClick();
      }}
      tabIndex="0"
      onKeyDown={e => {
        if (e.key === 'Enter' || e.key === 'Space') {
          onClick();
        }
      }}
    >
      <h5 className="d-flex justify-content-between">
        {schema.name}
        <FaChevronRight />
      </h5>
      {schema.has_workflow ? (
        <div className="workflow">
          <svg
            width="15"
            height="12"
            viewBox="0 0 15 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 7.5H6C5.58586 7.5 5.25 7.83586 5.25 8.25V11.25C5.25 11.6641 5.58586 12 6 12H9C9.41414 12 9.75 11.6641 9.75 11.25V8.25C9.75 7.83586 9.41414 7.5 9 7.5ZM4.5 0.75C4.5 0.335859 4.16414 0 3.75 0H0.75C0.335859 0 0 0.335859 0 0.75V3.75C0 4.16414 0.335859 4.5 0.75 4.5H2.99344L4.70813 7.50094C4.96828 7.05422 5.44688 6.75 6 6.75H6.00656L4.5 4.11352V3H9.75V1.5H4.5V0.75ZM14.25 0H11.25C10.8359 0 10.5 0.335859 10.5 0.75V3.75C10.5 4.16414 10.8359 4.5 11.25 4.5H14.25C14.6641 4.5 15 4.16414 15 3.75V0.75C15 0.335859 14.6641 0 14.25 0Z"
              fill="#03A04B"
            />
          </svg>
          <span className="ml-2">воркфлов</span>
        </div>
      ) : (
        ''
      )}
      <div className="text-muted entity-key">{schema.key}</div>
    </div>
  </div>
);

SchemaCard.propTypes = {
  schema: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SchemaCard;
