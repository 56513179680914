import { useEffect, useRef, useCallback } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import s from './Popover.module.scss';

const Popover = ({
  id,
  toggle,
  children,
  className,
  isActive,
  showDrop,
  setIsActive,
  width,
}) => {
  const node = useRef();
  const closePopover = useCallback(
    e => {
      if (node.current.contains(e.target)) return; // inside click

      setIsActive(false);
    },
    [setIsActive],
  );

  useEffect(() => {
    if (isActive) {
      document.addEventListener('mousedown', closePopover);
    } else {
      document.removeEventListener('mousedown', closePopover);
    }

    return () => {
      document.removeEventListener('mousedown', closePopover);
    };
  }, [closePopover, isActive]);

  return (
    <div className={s.popover} ref={node}>
      <a
        aria-haspopup="true"
        aria-expanded={isActive}
        id={id}
        role="button"
        href="#!"
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          setIsActive(!isActive);
        }}
        className={cx(s.toggleButton, className, {
          [s.isActive]: isActive,
          // eslint-disable-next-line css-modules/no-undef-class
          [s.showDrop]: showDrop,
        })}
      >
        {toggle}
      </a>

      <div
        style={{
          width,
        }}
        onClick={e => e.stopPropagation()}
        role="presentation"
        className={cx(s.content, {
          [s.isActive]: isActive,
        })}
      >
        {children}
      </div>
    </div>
  );
};

Popover.propTypes = {
  setIsActive: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  showDrop: PropTypes.bool,
  id: PropTypes.string.isRequired,
  toggle: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  width: PropTypes.number,
};

Popover.defaultProps = {
  isActive: false,
  showDrop: false,
  className: '',
  width: 230,
};

export default Popover;
