import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactSelect from 'react-select';

import { post } from 'core/fetch';
import PropTypes from 'prop-types';
import { useDebounce } from 'use-debounce';
import { getPropValue } from 'utils/formUtils';

import dropDownStyle from '../../ComponentStyle/DropDownStyle';

const MultiDropDown = ({
  name,
  options: propOptions,
  defaultValue,
  uiComponentOptions,
  relationshipOptions,
  limit,
}) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const { control, errors } = useFormContext();

  const componentOptionValue = uiComponentOptions?.value || 'id';
  const componentOptionLabel = uiComponentOptions?.label || 'name' || 'id';

  const fetchRelatedOptions = async (searchText, fieldToQuery) => {
    if (!relationshipOptions) return;

    setLoading(true);

    const filterWhere = relationshipOptions.filter_where;

    try {
      const items = await post(
        `/${relationshipOptions.related_entity}/search?limit=${limit}`,
        {
          $where: {
            ...(filterWhere ?? {}),
            ...(searchText
              ? { [fieldToQuery]: { $like: `%${searchText}%` } }
              : {}),
            _is_deleted: false,
          },
        },
      );

      setOptions(
        items.map(item => ({
          value: item[componentOptionValue],
          label: getPropValue(componentOptionLabel, item) || '(Blank)',
        })),
      );
      setLoading(false);
    } catch (err) {
      throw new Error(err);
    }
  };

  useEffect(() => {
    if (propOptions) {
      setOptions(propOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propOptions]);

  const transformedDefaultValue =
    defaultValue && defaultValue.length
      ? defaultValue.map(prop => ({
          value: prop[componentOptionValue],
          label: getPropValue(componentOptionLabel, prop),
        }))
      : undefined;

  const [inputText, setInputText] = useState();
  const [searchText] = useDebounce(inputText, 500);
  const { t } = useTranslation();

  useEffect(() => {
    if (searchText || searchText === '') {
      fetchRelatedOptions(searchText, componentOptionLabel);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const handleInputChange = inputValue => {
    setInputText(inputValue);
  };

  return (
    <Controller
      as={ReactSelect}
      styles={{
        control: (provided, state) => ({
          ...provided,
          minHeight: dropDownStyle.minHeight,
          marginTop: dropDownStyle.marginTop,
          boxShadow: state.isFocused && dropDownStyle.focusedBoxShadow,
          borderColor: errors[name]
            ? dropDownStyle.errorBorderColor
            : (state.isFocused && dropDownStyle.focusedBorderColor) ||
              dropDownStyle.borderColor,
          '&:hover': {
            borderColor: dropDownStyle.hoverBorderColor,
          },
        }),
        singleValue: provided => ({
          ...provided,
          ...dropDownStyle.textStyle,
        }),
        multiValue: provided => ({
          ...provided,
          ...dropDownStyle.textStyle,
          backgroundColor: dropDownStyle.multiValueBackgroundColor,
        }),
        option: provided => ({
          ...provided,
          ...dropDownStyle.textStyle,
        }),
        placeholder: provided => ({
          ...provided,
          ...dropDownStyle.textStyle,
        }),
        menuPortal: base => ({ ...base, zIndex: 99999 }),
      }}
      theme={theme => ({
        ...theme,
        colors: {
          ...theme.colors,
          ...dropDownStyle.themeColors,
        },
      })}
      onInputChange={handleInputChange}
      onMenuOpen={() => {
        if (!inputText) {
          fetchRelatedOptions();
        }
      }}
      menuPortalTarget={document.body}
      noOptionsMessage={() =>
        loading ? t('main.loading') : t('entity.control.no-option')
      }
      control={control}
      placeholder={t('entity.filter.select')}
      options={options}
      defaultValue={transformedDefaultValue}
      name={name}
      id={name}
      isMulti
    />
  );
};

MultiDropDown.propTypes = {
  options: PropTypes.array,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.any,
  limit: PropTypes.number,
  uiComponentOptions: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  relationshipOptions: PropTypes.shape({
    related_entity: PropTypes.string.isRequired,
    filter_where: PropTypes.object,
  }),
};

MultiDropDown.defaultProps = {
  options: [],
  defaultValue: [],
  limit: 30,
  uiComponentOptions: undefined,
  relationshipOptions: undefined,
};

export default MultiDropDown;
