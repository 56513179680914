import { useContext, useEffect } from 'react';
import { Container, Row, Col, Tab, Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useParams, Link } from 'react-router-dom';

import {
  EntityDefinition,
  FormLayout,
  FormLayoutWithWorkflow,
  GridSettings,
  Hooks,
  PrintableDocs,
  RelatedEntityFields,
  VisualWorkflow,
} from 'components/Schema';
import { SchemaContext } from 'contexts/SchemaContext';

import LayoutWithMenu from '../components/Layout/LayoutWithMenu';

const SchemaEdit = () => {
  const { entityType } = useParams();
  const { t } = useTranslation();
  const {
    actions: { fetchEntityJsonSchema },
    state,
  } = useContext(SchemaContext);

  useEffect(() => {
    fetchEntityJsonSchema(entityType);
  }, [entityType, fetchEntityJsonSchema]);

  const schema = state.entityJson;

  if (!schema) {
    return (
      <LayoutWithMenu>
        <Container />
      </LayoutWithMenu>
    );
  }

  const restApiEndpoint = `${window.location.origin}/api/api-docs/#/${entityType}`;

  return (
    <LayoutWithMenu>
      <Container>
        <Row className="mb-4">
          <Col>
            <h1>
              <Link to="/settings/schemas">{t('settings.entity')}:</Link>{' '}
              {schema.name}
            </h1>
          </Col>
        </Row>

        <Tab.Container id="schema-tabs" defaultActiveKey="entity-definition">
          <Nav variant="tabs" className="mb-4">
            <Nav.Item>
              <Nav.Link eventKey="entity-definition">
                {t('settings.entity-definition')}
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link eventKey="form-layout">
                {t('settings.form-layout')}{' '}
                {schema.hasWorkflow ? t('settings.with-workflow') : ''}
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link eventKey="workflow-and-automation">
                {t('settings.workflow-and-automation')}
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link eventKey="data-table">
                {t('settings.data-table')}
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link eventKey="printable-docs">
                {t('settings.printable-documents')}
              </Nav.Link>
            </Nav.Item>
          </Nav>

          <Tab.Content variant="pills">
            <Tab.Pane eventKey="entity-definition" mountOnEnter unmountOnExit>
              <h5>{t('settings.fields-list')}</h5>
              <EntityDefinition schema={schema} />

              <h5 className="mt-4">{t('settings.related-entities')}</h5>
              <RelatedEntityFields fields={schema.fields} />

              <h5 className="mt-4">REST API</h5>
              <p>
                {t('settings.api-address')}
                <br />
                <a href={restApiEndpoint} target="blank">
                  {restApiEndpoint}
                </a>
              </p>
            </Tab.Pane>

            <Tab.Pane eventKey="form-layout" mountOnEnter unmountOnExit>
              {schema.hasWorkflow ? (
                <>
                  <FormLayoutWithWorkflow schema={schema} />
                </>
              ) : (
                <>
                  <h5>{t('settings.fields')}</h5>
                  <FormLayout
                    formLayout={schema.formLayout}
                    fields={schema.fields}
                  />
                </>
              )}
            </Tab.Pane>

            <Tab.Pane
              eventKey="workflow-and-automation"
              mountOnEnter
              unmountOnExit
            >
              <h5>{t('settings.associated-workflow')}</h5>
              {schema.workflow.definition ? (
                <VisualWorkflow workflow={schema.workflow} />
              ) : (
                t('settings.no-associated-workflow')
              )}

              <h5 className="mt-4">{t('settings.hooks')}</h5>

              {schema.hooks.length > 0 ? (
                <Hooks hooks={schema.hooks} />
              ) : (
                <span>{t('settings.no-automation-workflow')}</span>
              )}
            </Tab.Pane>

            <Tab.Pane eventKey="data-table" mountOnEnter>
              <h5>{t('settings.grid-settings')}</h5>
              <GridSettings rowData={schema.gridSettings?.agGrid?.columnDefs} />

              <h5 className="mt-4">{t('settings.export-settings')}</h5>
              <GridSettings
                rowData={
                  schema.exportSettings?.columnDefs ||
                  schema.gridSettings?.agGrid?.columnDefs
                }
              />
            </Tab.Pane>

            <Tab.Pane eventKey="printable-docs" mountOnEnter>
              {schema.printableDocuments.length > 0 ? (
                <PrintableDocs printableDocuments={schema.printableDocuments} />
              ) : (
                <span>{t('settings.no-printable-documents')}</span>
              )}
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Container>
    </LayoutWithMenu>
  );
};
export default SchemaEdit;
