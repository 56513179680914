import { useContext, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import PropTypes from 'prop-types';

import './Layout.scss';

import { AppContext } from '../../contexts/AppContext';
import { useAuthContext } from '../../contexts/AuthContext';
import Nav from '../Nav/Nav';

const LayoutWithMenu = ({ children }) => {
  const history = useHistory();
  const match = useRouteMatch('/apps/:appKey/:entityKey?');

  const {
    actions: { logout },
  } = useAuthContext();

  const {
    state: { version, apps, menuItems, settings, isLoading, me },
    actions: { changeMenuItems },
  } = useContext(AppContext);

  useEffect(() => {
    if (match?.params?.appKey) {
      changeMenuItems(match.params.appKey);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apps]);

  const selectedAppKey = match?.params?.appKey;
  const selectedApp =
    selectedAppKey && !!apps?.length
      ? apps.find(app => app.key === selectedAppKey)
      : {};

  return (
    <>
      <Nav
        apps={apps}
        instanceIcon={settings?.instanceIcon}
        instanceName={settings?.name}
        isAdmin={me?.isAdmin}
        loading={isLoading}
        selectedApp={selectedApp}
        menuItems={menuItems}
        onAppClick={app => {
          history.push(`/apps/${app.key}`);
          changeMenuItems(app.key);
        }}
        onLogoutClick={logout}
        onSettingSchemaClick={() => {
          history.push(`/settings/schemas`);
          changeMenuItems(null);
        }}
        version={version}
      />
      <div className="Layout full-width">
        <main>{children}</main>
      </div>
    </>
  );
};

LayoutWithMenu.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default LayoutWithMenu;
