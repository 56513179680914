import { Handle } from 'react-flow-renderer';

/**
 * StartNode
 * custom react flow node
 * styles needs to be inline
 */
const StartNode = () => (
  <div
    style={{
      background: 'var(--primary)',
      borderRadius: '50%',
      color: 'var(--primary)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '16px',
      width: '16px',
    }}
  >
    <Handle type="source" position="right" />
  </div>
);

StartNode.propTypes = {};

export default StartNode;
