import { useFormContext, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CreatableSelect from 'react-select/creatable';

import PropTypes from 'prop-types';

import dropDownStyle from '../../ComponentStyle/DropDownStyle';

const DefaultField = ({ name }) => {
  const { errors, control } = useFormContext();
  const { t } = useTranslation();

  return (
    <Controller
      as={<CreatableSelect formatCreateLabel={string => string} isMulti />}
      styles={{
        control: (provided, state) => ({
          ...provided,
          minHeight: dropDownStyle.minHeight,
          marginTop: dropDownStyle.marginTop,
          boxShadow: state.isFocused && dropDownStyle.focusedBoxShadow,
          borderColor: errors[name]
            ? dropDownStyle.errorBorderColor
            : (state.isFocused && dropDownStyle.focusedBorderColor) ||
              dropDownStyle.borderColor,
          '&:hover': {
            borderColor: dropDownStyle.hoverBorderColor,
          },
        }),
        singleValue: provided => ({
          ...provided,
          ...dropDownStyle.textStyle,
        }),
        multiValue: provided => ({
          ...provided,
          ...dropDownStyle.textStyle,
          backgroundColor: dropDownStyle.multiValueBackgroundColor,
        }),
        option: provided => ({
          ...provided,
          ...dropDownStyle.textStyle,
        }),
        placeholder: provided => ({
          ...provided,
          ...dropDownStyle.textStyle,
        }),
        menuPortal: base => ({ ...base, zIndex: 99999 }),
      }}
      theme={theme => ({
        ...theme,
        colors: {
          ...theme.colors,
          ...dropDownStyle.themeColors,
        },
      })}
      control={control}
      options={[
        {
          label: t('entity.filter.blank'),
          value: '[BLANK]',
        },
      ]}
      placeholder={t('entity.filter.select')}
      onChange={([selected]) => selected}
      menuPortalTarget={document.body}
      name={name}
      id={`default-field-filter-${name}`}
    />
  );
};

DefaultField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default DefaultField;
