import { useContext, useState } from 'react';
import { MdOutlineDashboard } from 'react-icons/md';

import './SidebarMenu.scss';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';

import { AppContext } from '../../contexts/AppContext';
import ToggleButton from '../ToggleButton/ToggleButton';

const SidebarMenu = () => {
  const cookies = new Cookies();
  const { state } = useContext(AppContext);
  const [expanded, setExpanded] = useState(
    cookies.get('sidebar') || 'menu-expanded',
  );
  const [toggleValue, setToggleValue] = useState(
    expanded === 'menu-expanded' ? 'active' : '',
  );

  const onChange = () => {
    const value =
      expanded === 'menu-expanded' ? 'menu-collapsed' : 'menu-expanded';
    setExpanded(value);
    setToggleValue(value === 'menu-expanded' ? 'active' : '');
    cookies.set('sidebar', value);
  };

  const getSubMenus = app => {
    const subMenus = [];
    app.menu.map((menu, menuIndex) =>
      menu.item_groups.map(group =>
        group.items.map((item, itemIndex) => {
          if (itemIndex === 0) {
            subMenus.push({
              type: 'header',
              key: `sub_menu_header_${menu.key}`,
              label: menu.label,
            });
          }

          subMenus.push({
            type: 'menu',
            link: `/apps/${app.key}/${item.key}`,
            key: `sub_menu_item_${item.key}`,
            label: item.label,
            index: itemIndex,
          });
          if (
            menuIndex === app.menu.length - 1 &&
            itemIndex === group.items.length - 1 &&
            app.dashboard
          ) {
            subMenus.push({
              type: 'dashboard',
              link: `/apps/${app.key}`,
              key: `dashboard_item_${item.key}`,
              label: 'Хянах самбар',
              index: itemIndex,
            });
          }

          return item;
        }),
      ),
    );
    return subMenus;
  };

  return (
    <div className={`d-flex sidebar-menu ${expanded} widget-box`}>
      <div className="sidebar-links">
        <div className="header">
          <ToggleButton onChange={onChange} value={toggleValue} />
        </div>
        <ul className="main-menu-content">
          {state.apps.map(app => (
            <li className="main-menu" key={app.key}>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                href={app.menu.length === 0 ? `/apps/${app.key}` : '#'}
                className="main-menu-item"
              >
                <div className="icon">
                  <i className={`las ${app.icon}`} />
                </div>
                <span className="link hide">{app.name}</span>
              </a>

              <div
                className={`sub-menu-content ${
                  app.menu.length > 0 ? 'has-menu' : ''
                }`}
              >
                <ul>
                  {getSubMenus(app).map(item => (
                    <SubMenuItem item={item} key={`${item.key}_sub_menu`} />
                  ))}
                </ul>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SidebarMenu;

const SubMenuItem = ({ item }) => {
  switch (item.type) {
    case 'menu':
      return (
        <li key={item.key} className="sub-menu">
          <a href={item.link} className="sub-menu-item">
            <span>{item.label}</span>
          </a>
        </li>
      );
    case 'header':
      return (
        <li key={item.key}>
          <div className="sub-menu-header">{item.label}</div>
        </li>
      );
    case 'dashboard':
      return (
        <li key={item.key}>
          <a href={item.link} className="dashboard">
            <MdOutlineDashboard className="mr-2" />
            <span>{item.label}</span>
          </a>
        </li>
      );
    default:
      return <></>;
  }
};
SubMenuItem.propTypes = {
  item: PropTypes.object,
};
SubMenuItem.defaultProps = {
  item: {},
};
