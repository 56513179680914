import { useFormContext } from 'react-hook-form';

import cx from 'classnames';
import PropTypes from 'prop-types';

const NumericField = ({ name }) => {
  const { register, errors } = useFormContext();
  const fieldName = name.replace('.value', '');

  return (
    <>
      <input
        className={cx(`form-control`, {
          'is-invalid': errors[fieldName],
        })}
        ref={register({
          pattern: {
            value: /^\d*\.?\d*$/,
            message: 'Invalid number',
          },
        })}
        type="string"
        name={name}
        id={name}
        autoComplete="off"
      />

      {errors[fieldName] && (
        <span className="text-danger">{errors[fieldName].value.message}</span>
      )}
    </>
  );
};

NumericField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default NumericField;
