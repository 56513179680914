import { getDefaultView, getStateName } from 'utils/formUtils';

const ActionTypes = {
  FETCH_ENTITY_FAILURE: 'An error ocorred when tried to load this page.',

  RESET_ENTITY_DATA_REQUEST: '[Entity] Reset Entity Data Request',
  RESET_ENTITY_FORM_DATA_REQUEST: '[Entity] Reset Entity Form Data Request',
  RESET_ENTITY_STATES_REQUEST: '[Entity] Reset Entity States Success',

  FETCH_ALL_BY_ENTITY_KEY_REQUEST: '[Entity] Fetch All By Entity Key Request',
  FETCH_ALL_BY_ENTITY_KEY_SUCCESS: '[Entity] Fetch All By Entity Key Success',

  FETCH_ALL_GROUPED_BY_ENTITY_KEY_SUCCESS:
    '[Entity] Fetch All Grouped By Entity Key Success',

  FETCH_ONE_BY_ENTITY_KEY_REQUEST: '[Entity] Fetch One By Entity Key Request',
  FETCH_ONE_BY_ENTITY_KEY_SUCCESS: '[Entity] Fetch One By Entity Key Success',

  SET_ENTITY_VIEW: '[Entity] Set Entity View',

  FETCH_ENTITY_ATTACHMENT_REQUEST: '[Entity] Fetch Entity Attachment Request',
  FETCH_ENTITY_ATTACHMENT_SUCCESS: '[Entity] Fetch Entity Attachment Success',

  CREATE_ENTITY_ATTACHMENT_REQUEST: '[Entity] Insert Entity Attachment Request',
  CREATE_ENTITY_ATTACHMENT_SUCCESS: '[Entity] Insert Entity Attachment Success',
  CREATE_ENTITY_ATTACHMENT_FAIL: '[Entity] Insert Entity Attachment Fail',

  DELETE_ENTITY_ATTACHMENT_REQUEST: '[Entity] Delete Entity Attachment Request',
  DELETE_ENTITY_ATTACHMENT_SUCCESS: '[Entity] Delete Entity Attachment Success',
  DELETE_ENTITY_ATTACHMENT_FAIL: '[Entity] Delete Entity Attachment Fail',

  FETCH_ENTITY_HISTORY_REQUEST: '[Entity] Fetch Entity History Request',
  FETCH_ENTITY_HISTORY_SUCCESS: '[Entity] Fetch Entity History Success',

  FETCH_ENTITY_COMMENT_REQUEST: '[Entity] Fetch Entity Comment Request',
  FETCH_ENTITY_COMMENT_SUCCESS: '[Entity] Fetch Entity Comment Success',

  CREATE_ENTITY_COMMENT_REQUEST: '[Entity] Insert Entity Comment Request',
  CREATE_ENTITY_COMMENT_SUCCESS: '[Entity] Insert Entity Comment Success',
  CREATE_ENTITY_COMMENT_FAIL: '[Entity] Insert Entity Comment Fail',

  CREATE_ENTITY_FILE_UPLOAD_REQUEST: '[File] Insert File Upload Request',
  CREATE_ENTITY_FILE_UPLOAD_SUCCESS: '[File] Insert File Upload Success',
  CREATE_ENTITY_FILE_UPLOAD_FAIL: '[File] Insert File Upload Fail',

  CREATE_ENTITY_REQUEST: '[Entity] Create Entity Request',
  CREATE_ENTITY_SUCCESS: '[Entity] Create Entity Success',
  CREATE_ENTITY_FAIL: '[Entity] Create Entity Fail',

  UPDATE_ENTITY_REQUEST: '[Entity] Update Entity Request',
  UPDATE_ENTITY_FAIL: '[Entity] Delete Entity Fail',
  UPDATE_ENTITY_SUCCESS: '[Entity] Update Entity Success',

  DELETE_ENTITY_REQUEST: '[Entity] Delete Entity Request',
  DELETE_ENTITY_SUCCESS: '[Entity] Delete Entity Success',
  DELETE_ENTITY_FAIL: '[Entity] Delete Entity Fail',

  EXPORT_REQUEST: '[Entity] Export Request',
  EXPORT_SUCCESS: '[Entity] Export Success',
  EXPORT_FAIL: '[Entity] Export Fail',

  /** ********* WORKFLOW ACTIONS ********** */

  CREATE_ENTITY_WORKFLOW_REQUEST: '[Entity] Create Entity Workflow Request',
  CREATE_ENTITY_WORKFLOW_SUCCESS: '[Entity] Create Entity Workflow Success',
  CREATE_ENTITY_WORKFLOW_FAIL: '[Entity] Create Entity Workflow Fail',

  ENTITY_NEXT_STEP_REQUEST: '[Entity] Entity Next Step Request',
  ENTITY_NEXT_STEP_SUCCESS: '[Entity] Entity Next Step Success',
  ENTITY_NEXT_STEP_FAIL: '[Entity] Entity Next Step Fail',

  ENTITY_RETURN_STEP_REQUEST: '[Entity] Entity Return Step Request',
  ENTITY_RETURN_STEP_SUCCESS: '[Entity] Entity Return Step Success',
  ENTITY_RETURN_STEP_FAIL: '[Entity] Entity Return Step Fail',

  ENTITY_REJECT_REQUEST: '[Entity] Entity Reject Request',
  ENTITY_REJECT_SUCCESS: '[Entity] Entity Reject Success',
  ENTITY_REJECT_FAIL: '[Entity] Entity Reject Fail',

  ENTITY_TAKE_OWNERSHIP_REQUEST: '[Entity] Entity Take Ownership Request',
  ENTITY_TAKE_OWNERSHIP_SUCCESS: '[Entity] Entity Take Ownership Success',
  ENTITY_TAKE_OWNERSHIP_FAIL: '[Entity] Entity Take Ownership Fail',

  ENTITY_APPROVE_REQUEST: '[Entity] Entity Approve Request',
  ENTITY_APPROVE_SUCCESS: '[Entity] Entity Approve Success',
  ENTITY_APPROVE_FAIL: '[Entity] Entity Approve Fail',

  FETCH_AVAILABLE_WORKFLOW_STEPS_REQUEST:
    '[Entity] Entity Available Workflow Steps Request',
  FETCH_AVAILABLE_WORKFLOW_STEPS_SUCCESS:
    '[Entity] Entity Available Workflow Steps Success',

  /** ********* END WORKFLOW ACTIONS ********** */
};

const initialState = {
  // isLoading: false,
  // error: null,
  // schema: undefined,
  // data: undefined,
  // created: false,
  // updated: false,
  // deleted: false,
  // id: null,
};

const reducer = (state, action) => {
  const stateKey = getStateName(
    action.payload?.entityKey,
    action.payload?.entityId,
  );

  switch (action.type) {
    case ActionTypes.FETCH_ENTITY_FAILURE:
    case ActionTypes.ENTITY_NEXT_STEP_FAIL:
    case ActionTypes.ENTITY_RETURN_STEP_FAIL:
    case ActionTypes.ENTITY_REJECT_FAIL:
    case ActionTypes.ENTITY_TAKE_OWNERSHIP_FAIL:
    case ActionTypes.ENTITY_APPROVE_FAIL:
    case ActionTypes.UPDATE_ENTITY_FAIL: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          isLoading: false,
          error: action.payload.error,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.RESET_ENTITY_DATA_REQUEST: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          listData: undefined,
          formData: undefined,
          schema: undefined,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.RESET_ENTITY_FORM_DATA_REQUEST: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          formData: undefined,
          workflow: undefined,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.RESET_ENTITY_STATES_REQUEST: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          workflow: undefined,
          created: false,
          updated: false,
          deleted: false,
          error: undefined,
          success: undefined,
          schema: undefined,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.FETCH_ALL_BY_ENTITY_KEY_REQUEST: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],

          // UI works much better without this loading every request.
          // server-side sort seems to not work as well.
          isLoading: false,
          error: null,
          listData: [],
          total: 0,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.FETCH_ALL_BY_ENTITY_KEY_SUCCESS: {
      const {
        data: { results, total },
        schema,
      } = action.payload;

      const view = getDefaultView(schema);
      const currentView = state[stateKey]?.view;

      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          isLoading: false,
          listData: results,
          total,
          schema,
          view: currentView || view,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.FETCH_ALL_GROUPED_BY_ENTITY_KEY_SUCCESS: {
      const { data, schema } = action.payload;

      const view = getDefaultView(schema);
      const currentView = state[stateKey]?.view;

      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          isLoading: false,
          listData: data,
          schema,
          view: currentView || view,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.FETCH_ONE_BY_ENTITY_KEY_REQUEST: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          isLoading: true,
          error: null,
          formData: undefined,
          schema: undefined,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.FETCH_ONE_BY_ENTITY_KEY_SUCCESS: {
      const { data, schema } = action.payload;

      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          isLoading: false,
          formData: data,
          schema,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.SET_ENTITY_VIEW: {
      const { view } = action.payload;

      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          view,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.FETCH_ENTITY_HISTORY_REQUEST: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          error: null,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.FETCH_ENTITY_HISTORY_SUCCESS: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          histories: action.payload.response,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.FETCH_ENTITY_COMMENT_REQUEST: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          error: null,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.FETCH_ENTITY_COMMENT_SUCCESS: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          comments: action.payload.response,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.ENTITY_NEXT_STEP_REQUEST:
    case ActionTypes.ENTITY_RETURN_STEP_REQUEST:
    case ActionTypes.ENTITY_REJECT_REQUEST:
    case ActionTypes.ENTITY_TAKE_OWNERSHIP_REQUEST:
    case ActionTypes.ENTITY_APPROVE_REQUEST:
    case ActionTypes.UPDATE_ENTITY_REQUEST: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          error: null,
          updated: false,
          success: undefined,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.ENTITY_NEXT_STEP_SUCCESS:
    case ActionTypes.ENTITY_RETURN_STEP_SUCCESS:
    case ActionTypes.ENTITY_REJECT_SUCCESS:
    case ActionTypes.ENTITY_TAKE_OWNERSHIP_SUCCESS:
    case ActionTypes.ENTITY_APPROVE_SUCCESS:
    case ActionTypes.UPDATE_ENTITY_SUCCESS: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          updated: true,
        },
      };
      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.CREATE_ENTITY_WORKFLOW_REQUEST:
    case ActionTypes.CREATE_ENTITY_REQUEST: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          error: null,
          created: false,
          success: undefined,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.CREATE_ENTITY_WORKFLOW_SUCCESS:
    case ActionTypes.CREATE_ENTITY_SUCCESS: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          created: true,
          entityId: action.payload?.response?.id,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.CREATE_ENTITY_WORKFLOW_FAIL:
    case ActionTypes.CREATE_ENTITY_FAIL: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          error: action.payload.error,
          created: false,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.FETCH_AVAILABLE_WORKFLOW_STEPS_REQUEST: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          isLoading: true,
          error: null,
          workflow: undefined,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.FETCH_AVAILABLE_WORKFLOW_STEPS_SUCCESS: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          isLoading: false,
          workflow: action.payload.response,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.FETCH_ENTITY_ATTACHMENT_REQUEST: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          error: null,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.FETCH_ENTITY_ATTACHMENT_SUCCESS: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          attachments: action.payload.response,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.CREATE_ENTITY_ATTACHMENT_REQUEST: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          error: null,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.CREATE_ENTITY_ATTACHMENT_SUCCESS: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.CREATE_ENTITY_ATTACHMENT_FAIL: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          error: action.payload.error || action.payload.e,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.DELETE_ENTITY_ATTACHMENT_REQUEST: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          error: null,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.DELETE_ENTITY_ATTACHMENT_SUCCESS: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.DELETE_ENTITY_ATTACHMENT_FAIL: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          error: action.payload.error,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.CREATE_ENTITY_COMMENT_REQUEST: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          error: null,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.CREATE_ENTITY_COMMENT_SUCCESS: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.CREATE_ENTITY_COMMENT_FAIL: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          error: action.payload.e,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.CREATE_ENTITY_FILE_UPLOAD_REQUEST: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          error: null,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.CREATE_ENTITY_FILE_UPLOAD_SUCCESS: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          isLoading: false,
          success: true,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.CREATE_ENTITY_FILE_UPLOAD_FAIL: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          isLoading: false,
          error: action.payload.error,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.DELETE_ENTITY_REQUEST: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          error: null,
          deleted: false,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.DELETE_ENTITY_SUCCESS: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          deleted: true,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.DELETE_ENTITY_FAIL: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          error: action.payload.e,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.EXPORT_REQUEST: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          error: null,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.EXPORT_SUCCESS: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          error: null,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    case ActionTypes.EXPORT_FAIL: {
      const entityPayload = {
        [stateKey]: {
          ...state[stateKey],
          isLoading: false,
          error: action.payload.error || action.payload.e,
        },
      };

      return {
        ...state,
        ...entityPayload,
      };
    }

    default:
      throw new Error('Action could not be found.');
  }
};

export { initialState, ActionTypes, getStateName };

export default reducer;
