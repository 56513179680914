import { useEffect, useState } from 'react';
import { Tab, Nav } from 'react-bootstrap';
import MonacoEditor from 'react-monaco-editor';

import { get } from 'core/fetch';
import PropTypes from 'prop-types';

const PrintableDocs = ({ printableDocuments = [] }) => {
  const [templateId, setTemplateId] = useState(printableDocuments[0].id);
  const [template, setTemplate] = useState(null);

  useEffect(() => {
    get(`/printable_document/${templateId}`).then(response => {
      setTemplate(response);
    });
  }, [templateId]);

  const PREVIEW_HEIGHT = 400;
  const editorOptions = {
    lineHeight: 20,
    renderLineHighlight: 'all',
    minimap: { enabled: false },
    autoIndent: true,
    renderFinalNewline: true,
    wordWrap: 'on',
    wordWrapColumn: 80,
    readOnly: true,
  };

  return (
    <>
      <h5>Загварууд</h5>
      <div className="row mb-2">
        <div className="col-12 col-md-6 mb-2">
          <select
            className="form-control px-2"
            onChange={e => setTemplateId(e.target.value)}
          >
            {printableDocuments.map(document => (
              <option key={document.id} value={document.id}>
                {document.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-12 col-md-6 mb-2">
          <a
            type="button"
            className="btn btn-block btn-outline-primary"
            href="/apps/-/printable_document"
            target="blank"
          >
            <i className="fa fa-plus mr-2" />
            Шинэ загвар
          </a>
        </div>
      </div>

      <div>
        {template && (
          <Tab.Container id="printable-docs-tabs" defaultActiveKey="design">
            <Nav variant="tabs" className="mb-4">
              <Nav.Item>
                <Nav.Link eventKey="design">Дезайн</Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link eventKey="source">Код</Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content variant="pills">
              <Tab.Pane eventKey="source" mountOnEnter>
                <div>
                  <MonacoEditor
                    options={editorOptions}
                    height={PREVIEW_HEIGHT}
                    language="handlebars"
                    initialValue={template.template}
                    value={template.template}
                  />
                </div>
              </Tab.Pane>

              <Tab.Pane eventKey="design" mountOnEnter>
                <iframe
                  src={`/api/${template.entity_key}/doc/${templateId}`}
                  title="description"
                  height={PREVIEW_HEIGHT}
                  width="100%"
                  frameBorder="0"
                />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        )}
      </div>
    </>
  );
};

PrintableDocs.propTypes = {
  printableDocuments: PropTypes.array.isRequired,
};

export default PrintableDocs;
