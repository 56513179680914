import { Controller, useFormContext } from 'react-hook-form';

import { Editor } from '@tinymce/tinymce-react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import FieldLabel from './FieldLabel';

const HTMLField = ({ name, defaultValue, fieldItem }) => {
  const { control, errors } = useFormContext();
  const readOnly = fieldItem.ui_component_options?.readOnly || false;

  // useEffect(() => {
  //   setValue(name, defaultValue);
  // }, [setValue, defaultValue, name]);

  return (
    <FieldLabel fieldItem={fieldItem}>
      <div data-cy={name} className={cx({ 'is-invalid': errors[name] })}>
        <Controller
          control={control}
          name={name}
          defaultValue={defaultValue}
          render={({ onChange, onBlur, value: fieldValue }) => (
            <Editor
              value={fieldValue}
              initialValue={fieldValue}
              className="Editor"
              disabled={readOnly}
              init={{
                height: 250,
                forced_root_block: false,
                paste_data_images: true,
                // eslint-disable-next-line no-unused-vars
                images_upload_handler(blobInfo, success, failure) {
                  const file = blobInfo.blob();
                  const base64 = blobInfo.base64();
                  success(`data:${file.type};base64,${base64}`);
                },
                content_css: '/htmlEditor.css',
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount',
                ],
                toolbar:
                  'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | image | code',
              }}
              name={name}
              onEditorChange={onChange}
              onBlur={onBlur}
              control={control}
            />
          )}
        />

        {fieldItem.description && (
          <small className="form-text text-muted">
            {fieldItem.description}
          </small>
        )}
      </div>
    </FieldLabel>
  );
};

HTMLField.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.any,
  fieldItem: PropTypes.shape({
    placeholder: PropTypes.string,
    description: PropTypes.string,
    ui_component_options: PropTypes.shape({
      readOnly: PropTypes.bool,
    }),
  }).isRequired,
};

HTMLField.defaultProps = {
  defaultValue: '',
};

export default HTMLField;
