// eslint-disable-next-line no-unused-vars
import moment from 'moment';
import 'moment/locale/mn';
import 'moment/locale/en-au';
import PropTypes from 'prop-types';

const Moment = ({ format, fromNow, children }) => {
  moment.locale(process.env.REACT_APP_LANG);
  if (fromNow) {
    return moment(children).fromNow();
  }

  return moment(children).format(format);
};

Moment.propTypes = {
  format: PropTypes.string,
  fromNow: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

Moment.defaultProps = {
  format: 'DD/MM/YYYY hh:mm A',
  fromNow: false,
};

export default Moment;
