import ReactDOM from 'react-dom';

import AppContextProvider from 'contexts/AppContext';
import AuthContextProvider from 'contexts/AuthContext';
import DialogManagerContextProvider from 'contexts/DialogManagerContext';
import EntityContextProvider from 'contexts/EntityContext';

import './index.scss';
import './utils/i18n';
import App from './App';

ReactDOM.render(
  <AuthContextProvider>
    <AppContextProvider>
      <EntityContextProvider>
        <DialogManagerContextProvider>
          <App />
        </DialogManagerContextProvider>
      </EntityContextProvider>
    </AppContextProvider>
  </AuthContextProvider>,
  document.getElementById('root'),
);
