import { Controller, useFormContext } from 'react-hook-form';
import MonacoEditor from 'react-monaco-editor';

import cx from 'classnames';
import PropTypes from 'prop-types';

import FieldLabel from './FieldLabel';

const languageValueParser = (language, value) => {
  if (!value) {
    return undefined;
  }

  /** if you are implenting new language, it's required to define it in config-overrides.js */
  switch (language) {
    case 'json':
      return JSON.stringify(value, null, 4);
    default:
      return value;
  }
};

const MonacoEditorField = ({
  name,
  defaultValue,
  fieldItem,
  options: newOptions,
  ...props
}) => {
  const { control, errors } = useFormContext();
  const { readOnly, language } = fieldItem.ui_component_options;

  const options = {
    automaticLayout: true,
    readOnly,
    name,
    ...newOptions,
  };

  return (
    <FieldLabel fieldItem={fieldItem}>
      <div
        data-cy={name}
        className={cx('border', {
          'border-danger': errors[name],
          'is-invalid': errors[name],
        })}
      >
        <Controller
          control={control}
          name={name}
          defaultValue={languageValueParser(language, defaultValue)}
          render={({ onChange, onBlur, value: fieldValue }) => (
            <MonacoEditor
              options={options}
              onChange={onChange}
              onBlur={onBlur}
              height="400"
              language={language}
              initialValue={fieldValue}
              value={fieldValue}
              {...props}
            />
          )}
        />
      </div>

      {fieldItem.description && (
        <small className="form-text text-muted">{fieldItem.description}</small>
      )}
    </FieldLabel>
  );
};

MonacoEditorField.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.any,
  fieldItem: PropTypes.shape({
    placeholder: PropTypes.string,
    description: PropTypes.string,
    ui_component_options: PropTypes.shape({
      readOnly: PropTypes.bool,
      language: PropTypes.string,
    }),
  }).isRequired,
  options: PropTypes.object,
};

MonacoEditorField.defaultProps = {
  defaultValue: '',
  options: {},
};

export default MonacoEditorField;
