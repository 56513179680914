import { Card, Button } from 'react-bootstrap';

import './AppMenu.scss';
import PropTypes from 'prop-types';

const AppMenu = ({ app }) => (
  <div className="app-menu">
    <Button
      as="a"
      variant="link"
      className="w-100 p-0 text-decoration-none"
      href={`/apps/${app.key}`}
    >
      <Card key={app.key}>
        <Card.Body className="d-flex flex-column position-relative">
          <div
            className="d-flex justify-content-center"
            style={{ align: 'center' }}
          >
            <div className="menu-icon p-3 mb-4">
              <i
                className={`las ${app.icon} app-switcher-icon text-decoration-none`}
              />
            </div>
          </div>
          <h5 className="text-center">{app.name}</h5>
        </Card.Body>
      </Card>
    </Button>
  </div>
);

AppMenu.propTypes = {
  app: PropTypes.object,
};

AppMenu.defaultProps = {
  app: {},
};

export default AppMenu;
