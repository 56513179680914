import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactSelect from 'react-select';

import PropTypes from 'prop-types';

const WorkflowStepField = ({
  name,
  defaultValue,
  steps,
  labelForFinalised,
  labelForRejected,
}) => {
  const { control, errors } = useFormContext();
  const { t } = useTranslation();

  const options = [
    ...steps.map(workflowStep => ({
      label: workflowStep.name,
      value: {
        _workflow_current_step: workflowStep.order,
        _workflow_outcome: null,
      },
    })),
    {
      label: labelForFinalised,
      value: { _workflow_outcome: 1 },
    },
    {
      label: labelForRejected,
      value: { _workflow_outcome: 2 },
    },
  ];

  return (
    <Controller
      as={ReactSelect}
      styles={{
        control: provided => ({
          ...provided,
          borderColor: errors[name] ? 'red' : '#ced4da',
          minHeight: '3rem',
        }),
        menuPortal: base => ({ ...base, zIndex: 9999 }),
      }}
      menuPortalTarget={document.body}
      control={control}
      placeholder={t('entity.filter.select')}
      options={options}
      defaultValue={defaultValue}
      name={name}
      id={name}
      closeMenuOnSelect
      isMulti
    />
  );
};

WorkflowStepField.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.any,
  steps: PropTypes.array,
  labelForFinalised: PropTypes.string,
  labelForRejected: PropTypes.string,
};

WorkflowStepField.defaultProps = {
  steps: [],
  defaultValue: '',
  labelForFinalised: 'Approved',
  labelForRejected: 'Rejected',
};

export default WorkflowStepField;
