import jwtDecode from 'jwt-decode';
import { getCookie, deleteCookie } from 'utils/cookie';

import AuthActionTypes from './AuthActionTypes';

const apiToken = getCookie('api-token');

const initialState = {
  isLoading: false,
  error: null,
  authenticatedUser: apiToken ? jwtDecode(apiToken)?.data : false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case AuthActionTypes.Logout:
      return {
        ...state,
        isLoading: true,
      };
    case AuthActionTypes.LogoutSuccess:
      deleteCookie('api-token');

      return {
        ...state,
        ...initialState,
      };
    case AuthActionTypes.LogoutFail:
      // localStorage.clear();
      return {
        ...state,
        ...initialState,
      };
    case AuthActionTypes.Login:
      return {
        ...state,
        isLoading: true,
      };
    case AuthActionTypes.LoginSuccess:
      return {
        ...state,
        ...initialState,
        isLoading: false,
      };
    case AuthActionTypes.LoginWithNewPassword:
      return {
        ...state,
        ...initialState,
        isLoading: false,
      };
    case AuthActionTypes.LoginFail:
      return {
        ...state,
        ...initialState,
        isLoading: false,
        error: action.error,
      };
    case AuthActionTypes.ResetPassword:
      return {
        ...state,
        isLoading: true,
      };
    case AuthActionTypes.ResetPasswordSuccess:
      return {
        ...state,
        ...initialState,
        isLoading: false,
      };
    case AuthActionTypes.ResetPasswordFail:
      return {
        ...state,
        ...initialState,
        isLoading: false,
        error: action.error,
      };
    default:
      throw new Error();
  }
};

export { initialState };

export default reducer;
