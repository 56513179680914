import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineArrowNarrowRight } from 'react-icons/all';

import { isObject } from 'lodash';
import moment from 'moment';
import 'moment/locale/mn';
import 'moment/locale/en-au';
import PropTypes from 'prop-types';
import { getInitialLetter } from 'utils/text';

import { AppContext } from 'contexts/AppContext';

import {
  ENTITY_LOG_ACTION_OBJECT,
  ENTITY_LOG_ACTION_UPDATE,
} from '../../constants/entityLogActions';
import EntityHistoryCircle from '../EntityHistoryCircle/EntityHistoryCircle';

import './EntityHistoryListItem.scss';

const EntityHistoryListItem = ({ history, schema }) => {
  const {
    state: { settings },
  } = useContext(AppContext);
  const { t } = useTranslation();

  moment.locale(process.env.REACT_APP_LANG);
  const fieldsSchema = schema.fields;
  let fields = [];
  if (history.old && history.new) {
    fields = Object.keys(history.old)
      // eslint-disable-next-line no-prototype-builtins
      .filter(field => fieldsSchema.hasOwnProperty(field))
      .map(field => ({
        field,
        label: fieldsSchema[field].label,
        old: history.old[field],
        new: history.new[field],
      }));
  }
  const safeParse = value => {
    if (!value) return <span>(хоосон)</span>;

    if (isObject(value)) {
      return <pre>{JSON.stringify(value, null, '\t')}</pre>;
    }

    const dateValue = moment(value, moment.ISO_8601, true);
    if (dateValue.isValid()) {
      return (
        <span>
          {dateValue
            .local()
            .format(`${settings.dateFormat} ${settings.timeFormat}`)}
        </span>
      );
    }

    return <span>{value}</span>;
  };

  return (
    <div className="EntityHistoryListItem d-flex">
      <div className="history-action mr-2">
        <EntityHistoryCircle action={history.action} />
        <div className="history-action-user circle circle-gray rounded-circle">
          <strong>{getInitialLetter(history.created_by?.first_name)}</strong>
        </div>
      </div>
      <div className="flex-grow-1">
        <p className="m-0">
          <span className="my-0 mr-3">
            <strong className="mr-1 text-primary">
              {history.created_by
                ? history.created_by.first_name
                : t('entity.comment.unknown')}
            </strong>
            <span className="normal-text">
              {t(
                `entity.history.${
                  ENTITY_LOG_ACTION_OBJECT[history.action]?.text
                }`,
              )}
            </span>
          </span>
          <span className="normal-text history-detail-date">
            {moment(history._updated_at).fromNow()},{' '}
            {moment(history._updated_at).format('lll')}
          </span>
        </p>
        {history.action === ENTITY_LOG_ACTION_UPDATE && (
          <table className="history-detail-table table table-borderless table-sm m-0 mt-2">
            <tbody>
              {fields.map(({ field, label, new: newObject, old }) => (
                <tr key={field}>
                  <th>{label}:</th>
                  <td>
                    <span>{safeParse(old)}</span>
                    <HiOutlineArrowNarrowRight />
                    <i className="far fa-arrow-right fa-md icon mx-3" />
                    <span>{safeParse(newObject)}</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

EntityHistoryListItem.propTypes = {
  history: PropTypes.object.isRequired,
  schema: PropTypes.any.isRequired,
};

export default EntityHistoryListItem;
