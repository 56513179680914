const RelationshipFields = ['SingleDropDown', 'MultiDropDown', 'Grid'];

const RelationshipType = {
  OneToMany: 'Many to One',
  ManyToMany: 'Many to Many',
  ManyToOne: 'Many to One',
};

const RelationshipFieldsTypes = {
  SingleDropDown: RelationshipType.ManyToOne,
  MultiDropDown: RelationshipType.ManyToMany,
  Grid: RelationshipType.OneToMany,
};

const UIComponents = {
  Checkbox: 'Checkbox',
  DateField: 'DateField',
  FileField: 'FileField',
  Grid: 'Grid',
  MapLocation: 'MapLocation',
  LocationField: 'LocationField',
  MultiDropDown: 'MultiDropDown',
  NumericField: 'NumericField',
  SingleDropDown: 'SingleDropDown',
  WorkflowStep: 'WorkflowStep',
};

export {
  RelationshipFields,
  RelationshipFieldsTypes,
  RelationshipType,
  UIComponents,
};
