import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

import i18n from 'i18next';
import PropTypes from 'prop-types';

const ConfirmationExitModal = ({
  title,
  onHide,
  onCancel,
  onConfirm,
  modalLevel,
  cancelMessage,
  confirmMessage,
  ...props
}) => (
  <Modal
    backdropClassName={`modal-level-${modalLevel}`}
    className={`modal-level-${modalLevel}`}
    onHide={onHide}
    {...props}
  >
    <Modal.Header className="border-0" closeButton>
      <Modal.Title>
        <h5 className="text-center text-dark pt-3">{title}</h5>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body className="d-flex justify-content-center px-4 pb-4">
      <Button variant="primary" className="mr-2" onClick={onCancel}>
        {cancelMessage}
      </Button>
      <Button
        variant="outline-primary"
        data-cy="btn-confirm-exit"
        onClick={onConfirm}
      >
        {confirmMessage}
      </Button>
    </Modal.Body>
  </Modal>
);

ConfirmationExitModal.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  modalLevel: PropTypes.number,
  onHide: PropTypes.func,
  onCancel: PropTypes.func,
  cancelMessage: PropTypes.string,
  onConfirm: PropTypes.func,
  confirmMessage: PropTypes.string,
};

ConfirmationExitModal.defaultProps = {
  show: false,
  onHide: () => {},
  onCancel: () => {},
  onConfirm: () => {},
  modalLevel: 1,
  cancelMessage: i18n.t('entity.modal.cancel'),
  confirmMessage: i18n.t('entity.modal.close-without-save'),
  title: i18n.t('entity.modal.saving-confirm'),
};

export default ConfirmationExitModal;
