import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import s from './AgGrid.module.scss';

const AgGrid = ({
  rowData,
  columnDefs,
  onGridReady,
  onCellClicked,
  onSortChanged,
  getRowNodeId,
  defaultColDef = {
    autoHeight: true,
    resizable: true,
  },
  rowSelection,
  immutableData,
  suppressHorizontalScroll,
  rowStyle,
  className,
  frameworkComponents,
  enableCellTextSelection,
  onRowSelected,
}) => {
  const [gridApi, setGridApi] = useState(null);
  const { t } = useTranslation();

  const autoSizeColumns = columnApi => {
    const skipHeader = false;
    const allColumnIds = [];
    columnApi.getAllColumns().forEach(column => {
      allColumnIds.push(column.colId);
    });
    columnApi.autoSizeColumns(allColumnIds, skipHeader);
  };

  const gridReady = params => {
    setGridApi(params.api);

    if (window.innerWidth > 768) {
      params.api.sizeColumnsToFit();
      return onGridReady(params.api);
    }

    autoSizeColumns(params.columnApi);
    return onGridReady(params.api);
  };

  const gridResize = useCallback(() => {
    if (!gridApi) return;

    if (window.innerWidth > 768) {
      // obs: sizeColumnsToFit() warning message still there, it is an issue of aggrid
      gridApi.sizeColumnsToFit();
    }
  }, [gridApi]);

  useEffect(() => {
    window.addEventListener('resize', gridResize);

    return () => window.removeEventListener('resize', gridResize);
  }, [gridResize]);

  return (
    <div className={cx('ag-theme-material EntityTable', className, s.grid)}>
      <AgGridReact
        rowClass={cx({ 'row-pointer': onCellClicked })}
        rowData={rowData}
        onGridReady={gridReady}
        enableCellTextSelection={enableCellTextSelection}
        onCellClicked={onCellClicked}
        onSortChanged={onSortChanged}
        getRowNodeId={getRowNodeId}
        defaultColDef={defaultColDef}
        rowSelection={rowSelection}
        immutableData={immutableData}
        suppressHorizontalScroll={suppressHorizontalScroll}
        rowStyle={rowStyle}
        frameworkComponents={frameworkComponents}
        onRowSelected={onRowSelected}
        overlayNoRowsTemplate={t('entity.grid.no-row')}
      >
        {columnDefs.map(({ entityKey, ...column }) => (
          <AgGridColumn {...column} key={column.field} />
        ))}
      </AgGridReact>
    </div>
  );
};

AgGrid.propTypes = {
  frameworkComponents: PropTypes.any,
  rowData: PropTypes.array,
  columnDefs: PropTypes.array,
  onGridReady: PropTypes.func,
  onCellClicked: PropTypes.func,
  onSortChanged: PropTypes.func,
  getRowNodeId: PropTypes.func,
  defaultColDef: PropTypes.shape({
    autoHeight: PropTypes.bool,
    resizable: PropTypes.bool,
  }),
  rowSelection: PropTypes.string,
  immutableData: PropTypes.bool,
  suppressHorizontalScroll: PropTypes.bool,
  rowStyle: PropTypes.object,
  className: PropTypes.string,
  onRowSelected: PropTypes.func,
  enableCellTextSelection: PropTypes.bool,
};

AgGrid.defaultProps = {
  onRowSelected: undefined,
  frameworkComponents: undefined,
  rowData: [],
  columnDefs: [],
  onGridReady: () => {},
  onCellClicked: undefined,
  onSortChanged: undefined,
  getRowNodeId: undefined,
  defaultColDef: {
    autoHeight: true,
    resizable: true,
  },
  rowSelection: 'single',
  immutableData: false,
  suppressHorizontalScroll: false,
  rowStyle: {},
  className: undefined,
  enableCellTextSelection: false,
};

export default AgGrid;
