export const translation_mn = {
  entity: {
    main: {
      details: 'МЭДЭЭЛЭЛ',
      attachments: 'ХАВСРАЛТ',
      history: 'ТҮҮХ',
      comments: 'ТАЙЛБАР',
    },
    filter: {
      'none-selected': '. . .',
      apply: 'Биелүүлэх',
      'add-filter': 'Шүүлтүүр',
      'pick-field': 'Баганаа сонгоно уу',
      blank: '(Хоосон)',
      select: 'Сонго ...',
    },
    buttons: {
      create: 'Шинэ',
      export: 'Экспорт',
      print: 'Хэвлэх',
      'export-csv': 'Excel файл',
      'edit-record': 'Бичлэгийг засах',
      'create-record': 'Шинийг үүсгэх',
      'view-record': 'Бичлэгийг харах',
    },
    modal: {
      save: 'Хадгалах',
      create: 'Үүсгэх',
      'create-new': 'Шинийг үүсгэх',
      edit: 'Засах',
      close: 'Хаах',
      'saving-confirm': 'Та хадгалахгүйгээр хаахдаа итгэлтэй байна уу?',
      cancel: 'Болих',
      confirmation: 'Баталгаажуулах',
      confirm: 'Зөвшөөрөх',
      submit: 'Илгээх',
      reject: 'Цуцлах',
      'close-without-save': 'Хадгалахгүйгээр хаах',
      'delete-record': 'Бичлэгийг устгах',
      'deleting-confirm-title': 'Устгахыг баталгаажуулах',
      'deleting-confirm-message':
        'Та энэ бичлэгийг устгахдаа итгэлтэй байна уу?',
      'deleting-confirm-file-message':
        'Та энэ файлыг устгахдаа итгэлтэй байна уу?',
      'more-info-title': 'Нэмэлт мэдээлэл шаардлагатай',
      'more-info-message': 'Шалтгаанаа нарийн оруулна уу',
      'rejecting-confirm-title': 'Цуцлахыг баталгаажуулах',
      'rejecting-confirm-message':
        'Хэрэв энэ бичлэгийг цуцалвал дахин засах боломжгүй болно.',
      'approving-confirm-title': 'Зөвшөөрөхийг баталгаажуулах',
      'approving-confirm-message':
        'Энэ бичилт зөвшөөрөгдөөд воркфловын эцэсийн алхам дээр очиж дуусахыг анхаарна уу!',
    },
    control: {
      'no-option': 'cонголт байхгүй',
    },
    grid: {
      'no-row': 'Бичлэг байхгүй байна',
      'per-page': 'нэг хуудсанд',
      'page-showing': 'Хуудас',
      'page-of': 'Нийт',
    },
    comment: {
      'add-comment': 'Тайлбар оруулах...',
      save: 'Хадгалах',
      cancel: 'Болих',
      unknown: 'Үл мэдэх',
    },
    history: {
      'attachment-uploaded': 'хавсралт файл оруулсан',
      'attachment-downloaded': 'хавсралт файл татсан',
      'attachment-deleted': 'хавсралт файл устгасан',
      'entity-updated': 'өгөгдөл шинэчилсэн',
      'entity-created': 'өгөгдөл үүсгэсэн',
      'user-assigned': 'хэрэглэгчид оноосон',
      'record-rejected': 'энэ бичлэгийн татгалзсан',
      'record-approved': 'энэ бичлэгийн зөвшөөрсөн',
      'record-picked': 'энэ бичлэгийг авсан',
      'record-backed': 'энэ бичлэгийг буцаасан',
      'record-assigned': 'энэ бичлэгийг оноосон',
    },
    attachment: {
      'upload-files': 'Файл хавсаргах',
      'click-here': 'Энд дараад эсвэл чирээд файлаа сонгоно уу...',
    },
    validation: {
      required: 'Хоосон байж болохгүй',
      'min-len': 'Талбарын хамгийн бага тэмдэгтийн хэмжээ бол ',
      'max-len': 'Талбарын хамгийн их тэмдэгтийн хэмжээ бол ',
      min: 'Талбарын тоон утга хамгийн багадаа ',
      max: 'Талбарын тоон утга хамгийн ихдээ ',
      integer: 'Бүхэл тоо оруулна уу',
      number: 'Тоо оруулна уу',
      pattern: 'Өгөгдлийн формат буруу байна',
    },
    view: {
      'view-record': 'Харах',
    },
  },
  main: {
    apps: 'Апп',
    'switch-apps': 'Апп сонгох',
    settings: 'Тохиргоо',
    logout: 'Гарах',
    signin: 'НЭВТРЭХ',
    update: 'ШИНЭЧЛЭХ',
    copyright: 'ЖАКАРАНДА СИСТЕМС 2022',
    tsum: 'ЦӨМ СИСТЕМ',
    loading: 'Ачааллаж байна ...',
    'report-app-name': 'Тайлан',
  },
  settings: {
    entity: 'Интити',
    entities: 'Интити жагсаалт',
    'search-entities': 'Интити хайх',
    'entity-definition': 'Тодорхойлолт',
    'related-entities': 'Холбоотой интити',
    'form-layout': 'Форм байршил',
    'with-workflow': '(WF)',
    'workflow-and-automation': 'Воркфлов',
    'data-table': 'Хүснэгтийн бүтэц',
    'printable-documents': 'Хэвлэх загвар',
    'fields-list': 'Талбарын жагсаалт',
    'api-address': 'Энэ хаягыг ашиглаад өөр системээс хандах боломжтой',
    fields: 'Талбарууд',
    'associated-workflow': 'Холбоотой воркфлов',
    'no-associated-workflow': 'Одоогоор үүсгэсэн воркфлов байхгүй байна',
    hooks: 'Хүүк буюу автомат үйлдэл',
    'no-automation-workflow': 'Одоогоор бүртгэлтэй хүүк байхгүй байна',
    'grid-settings': 'Хүснэгт тохиргоо',
    'export-settings': 'Экспорт тохиргоо',
    'no-printable-documents': 'Одоогоор хэвлэх загвар байхгүй байна',
    'no-entities': 'Одоогоор холбоотой интити байхгүй байна',
    'no-entities-found': 'Тохирох интити олдсонгүй',
  },
  message: {
    'entity-created': 'Бичлэг амжилттай үүслээ',
    'entity-deleted': 'Бичлэг амжилттай устлаа',
    'entity-updated': 'Бичилт амжилттай шинэчлэгдлээ',
    'entity-saved': 'Бичилт амжилттай хадгалагдлаа',
    'comment-added': 'Тайлбар амжилттай үүслээ',
    'attachment-inserted': 'Хавсралт файл ажилттай хадгалагдлаа',
    'attachment-deleted': 'Хавсралт файл амжилттай устлаа',
  },
};
