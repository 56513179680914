import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { IoShareSocial, IoShareSocialOutline } from 'react-icons/io5';
import { useParams } from 'react-router-dom';

import moment from 'moment';
import PropTypes from 'prop-types';

import ActionLabels from '../../../../constants/ActionLabels';
import { useEntityContext } from '../../../../contexts/EntityContext';
import { getBadgeColor, getOutcomeLabel } from '../../../../utils/worflowUtils';
import WorkflowStepper from '../../../WorkflowStepper/WorkflowStepper';

const WorkflowRenderer = props => {
  const params = useParams();
  const { loadEntitySchema } = useEntityContext();
  const { _workflow_definition, _workflow_outcome, id } = props.data;
  const [workflow, setWorkflow] = useState(null);
  const [showWorkflow, setShowWorkflow] = useState(false);
  const entityKey =
    props.colDef.cellRendererParams?.entityKey || params.entityKey;
  const emptyValue = <span />;

  if (!_workflow_definition) return emptyValue;

  const wfDef = JSON.parse(_workflow_definition);
  const stepNumber = parseInt(props.value, 10);
  const step = wfDef.steps.find(item => item.order === stepNumber);

  if (!step) return emptyValue;

  const toggleWorkflow = async () => {
    if (!workflow) {
      const schema = await loadEntitySchema(entityKey, id);
      setWorkflow(schema.workflow);
    }
    props.node.setRowHeight(!showWorkflow ? 80 : 45);
    props.api.onRowHeightChanged();
    setShowWorkflow(!showWorkflow);
  };

  moment.locale(process.env.REACT_APP_LANG);
  const renderOutcomeMessage = () => (
    <div className="border-left pl-3">
      {workflow.historyGrouped[workflow.historyGrouped.length - 1].items.map(
        historyItem => (
          <div
            key={`${historyItem.id}${historyItem.date}`}
            className="line-height-initial"
          >
            Энэ бүртгэл <span>{ActionLabels[historyItem.workflow_action]}</span>
            {' : '}
            <span className="font-weight-bold">
              {historyItem.ownerName || historyItem.created_by || 'BOT'}{' '}
            </span>{' '}
            {moment(historyItem.date).local().fromNow()}
          </div>
        ),
      )}
    </div>
  );

  return (
    <>
      <div className="align-items-center">
        <Button
          className="no-shadow"
          variant="link"
          onClick={async ev => {
            ev.stopPropagation();
            await toggleWorkflow();
          }}
        >
          {showWorkflow && workflow ? (
            <IoShareSocial color="var(--primary)" />
          ) : (
            <IoShareSocialOutline color="var(--primary)" />
          )}
        </Button>
        <span
          className={`badge ${getBadgeColor(_workflow_outcome, stepNumber)}`}
        >
          {_workflow_outcome
            ? getOutcomeLabel(wfDef, _workflow_outcome)
            : step.name}
        </span>
      </div>
      {showWorkflow && workflow && (
        <div className="d-flex align-items-center">
          <WorkflowStepper
            workflowOutcome={_workflow_outcome}
            workflow={workflow}
            noAlert
          />
          {_workflow_outcome && renderOutcomeMessage()}
        </div>
      )}
    </>
  );
};

WorkflowRenderer.propTypes = {
  colDef: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  api: PropTypes.any.isRequired,
  node: PropTypes.any.isRequired,
  data: PropTypes.shape({
    id: PropTypes.number,
    _workflow_definition: PropTypes.string,
    _workflow_outcome: PropTypes.number,
    ui_component_options: PropTypes.shape({
      readOnly: PropTypes.bool,
    }),
  }).isRequired,
};

export default WorkflowRenderer;
