import { useEffect, useState } from 'react';
import { Dropdown, Button, Navbar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  FaChevronLeft,
  FaChevronRight,
  FaCog,
  FaSignOutAlt,
  FiMenu,
  IoMdNotifications,
  MdApps,
} from 'react-icons/all';
import { GoSettings } from 'react-icons/go';
import { Link, useLocation } from 'react-router-dom';

import cx from 'classnames';
import PropTypes from 'prop-types';

import './Nav.scss';

function MobileActionButton({ onClick, children }) {
  return (
    <Button
      variant="primary"
      className="d-flex align-items-center justify-content-between p-3 mx-3 my-2 font-weight-bold"
      onClick={e => {
        e.preventDefault();
        onClick();
      }}
    >
      {children}
    </Button>
  );
}
MobileActionButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
MobileActionButton.defaultProps = {
  onClick: () => {},
};

const Nav = ({
  apps,
  instanceIcon,
  instanceName,
  isAdmin,
  loading,
  selectedApp,
  // menuItems,
  onAppClick,
  onLogoutClick,
  onSettingSchemaClick,
  // version,
}) => {
  const [switchAppsMenuMobileOpened, setSwitchAppsMenuMobileOpened] = useState(
    false,
  );
  const [mobileMeuOpened, setMobileMenuOpened] = useState(false);
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    setMobileMenuOpened(false);
    setSwitchAppsMenuMobileOpened(false);
  }, [location]);

  return (
    <div className="nav-header">
      <Navbar
        className={cx('main-navbar', { 'bg-gray-x-light': loading })}
        expand="lg"
      >
        <div className="d-flex">
          {/* <Dropdown as={NavItem} className="d-none d-lg-block">
          <Dropdown.Toggle as={NavLink} className="nav-dropdown app-switcher">
            <i className="fas fa-th" />
          </Dropdown.Toggle>
          <Dropdown.Menu className="app-switcher-dropdown">
            <p className="app-switcher-label">{t('main.switch-apps')}</p>
            {apps?.map(app => (
              <Dropdown.Item
                key={app.id}
                onClick={e => {
                  e.preventDefault();
                  onAppClick(app);
                }}
              >
                <i className={`${app.icon} app-switcher-icon`} />
                {app.name}
              </Dropdown.Item>
            ))}
            {version && <div className="version text-muted mt-2" />}
          </Dropdown.Menu>
        </Dropdown> */}
          <Button
            className="d-block d-lg-none p-0"
            variant="link"
            onClick={() => setMobileMenuOpened(true)}
          >
            <FiMenu size={30} color="white" />
          </Button>
          {/* {selectedApp?.key && (
          <Link
            to={`/apps/${selectedApp?.key}`}
            className="d-flex align-items-center text-decoration-none mr-0 mr-lg-4 selected-app p-3 p-lg-0"
            data-cy="selected-app-desktop"
          >
            <div className="nav-icon d-flex justify-content-center align-items-center text-decoration-none">
              <i
                className={cx('fa-lg', {
                  [selectedApp?.icon]: selectedApp,
                  'fas fa-shipping-fast': !selectedApp,
                })}
              />
            </div>
            <p className="text-white m-0 font-weight-bold ml-3">
              {selectedApp?.name}
            </p>
          </Link>
        )} */}
        </div>
        {loading || (
          <div className={cx('navbar-items', { opened: mobileMeuOpened })}>
            {switchAppsMenuMobileOpened && (
              <div className="position-absolute w-100 h-100 bg-white z-100 switch-apps-mobile-menu d-block d-lg-none">
                <Button
                  variant="link"
                  className="d-flex align-items-center text-decoration-none mr-0 mr-lg-4 selected-app px-3 py-4 p-lg-0 w-100"
                  onClick={() => setSwitchAppsMenuMobileOpened(false)}
                >
                  <FaChevronLeft size={12} color="black" />
                  <p className="text-dark m-0 font-weight-bold ml-3">
                    {t('main.switch-apps')}
                  </p>
                </Button>
                <div className="py-1">
                  {apps?.map(app => (
                    <Button
                      key={app.id}
                      variant="link"
                      className="d-flex w-100 align-items-center py-2 text-decoration-none"
                      onClick={e => {
                        e.preventDefault();
                        onAppClick(app);
                      }}
                    >
                      <span className="badge badge-primary py-2 px-2 mr-3 d-flex align-items-center justify-content-center d-flex square">
                        <i className={`${app.icon} h5 m-0`} />
                      </span>
                      <span className="text-dark">{app.name}</span>
                    </Button>
                  ))}
                </div>
                <div className="d-flex flex-column w-100">
                  <MobileActionButton onClick={onSettingSchemaClick}>
                    <span>{t('main.settings')}</span>
                    <FaCog size={20} color="white" />
                  </MobileActionButton>
                  <MobileActionButton onClick={onLogoutClick}>
                    <span>{t('main.logout')}</span>
                    <FaSignOutAlt size={20} color="white" />
                  </MobileActionButton>
                </div>
              </div>
            )}
            <Button
              size="sm"
              className="d-flex d-lg-none navbar-items-close-button"
              variant="link"
              onClick={() => setMobileMenuOpened(false)}
            >
              <FaChevronLeft size={12} color="black" />
            </Button>
            {selectedApp?.key && (
              <Link
                to={`/apps/${selectedApp?.key}`}
                className="d-flex align-items-center text-decoration-none mr-0 mr-lg-4 selected-app p-3 p-lg-0 d-lg-none"
              >
                <div className="nav-icon d-flex justify-content-center align-items-center text-decoration-none">
                  <i
                    className={`${
                      selectedApp ? selectedApp?.icon : 'fas fa-shipping-fast'
                    } fa-lg`}
                  />
                </div>
                <p className="text-dark m-0 font-weight-bold ml-3">
                  {selectedApp?.name}
                </p>
              </Link>
            )}
            {!selectedApp?.name && (
              <div className="border-bottom border-bottom-gray-light d-block d-lg-none">
                <Button
                  variant="link"
                  className="d-flex align-items-center justify-content-between px-3 py-4 w-100"
                  onClick={() => setSwitchAppsMenuMobileOpened(true)}
                >
                  <div className="d-flex align-items-center">
                    <MdApps size={24} color="var(--primary)" className="mr-2" />
                    <span className="text-dark text-decoration-none">
                      {t('main.switch-apps')}
                    </span>
                  </div>
                  <FaChevronRight size={12} color="black" className="mr-2" />
                </Button>
              </div>
            )}
            <div
              className="d-flex flex-grow-1 align-items-center flex-column flex-lg-row"
              id="basic-navbar-nav"
            >
              <div className="d-flex align-items-center">
                <Button variant="link" className="mr-2 d-block d-lg-none">
                  <IoMdNotifications color="white" size={24} />
                </Button>
                <div className="nav-icon p-1 flex-grow-0 flex-shrink-0 flex-basis-auto">
                  <img
                    className="w-100 h-100 object-fit-contain"
                    src={instanceIcon}
                    alt={instanceName}
                  />
                </div>
              </div>

              <Link
                to="/"
                className="business-name text-dark ml-3 d-none d-lg-block flex-grow-0 flex-shrink-0 flex-basis-auto"
              >
                {instanceName}
              </Link>

              {/* <NavBootStrap className="mr-auto align-items-center">
              {menuItems?.map(dropdown => (
                <Dropdown key={dropdown.key} as={NavItem}>
                  {dropdown.entityKey ? (
                    <Link
                      className="nav-dropdown text-white p-2"
                      to={`/apps/${selectedApp?.key}/${dropdown.entityKey}`}
                      data-cy="menu-item"
                    >
                      {dropdown.label}
                    </Link>
                  ) : (
                    <>
                      <Dropdown.Toggle
                        as={NavLink}
                        className="nav-dropdown text-white"
                        data-cy="menu-item-dropdown"
                      >
                        {dropdown.label}
                        <i className="fas fa-chevron-down nav-dropdown-toggle-icon" />
                      </Dropdown.Toggle>
                      <DropdownList
                        appKey={selectedApp?.key}
                        optionsList={dropdown.item_groups}
                      />
                    </>
                  )}
                </Dropdown>
              ))}
            </NavBootStrap> */}

              <div className="mobile-action-buttons d-flex d-lg-none w-100 flex-column my-n1">
                {selectedApp?.name && (
                  <Button
                    variant="primary"
                    className="d-flex align-items-center justify-content-between p-3 mx-3 my-2 font-weight-bold"
                    onClick={() => setSwitchAppsMenuMobileOpened(true)}
                  >
                    <span>{t('main.switch-apps')}</span>
                    <FaChevronRight size={20} color="white" />
                  </Button>
                )}
                {isAdmin && (
                  <MobileActionButton onClick={onSettingSchemaClick}>
                    <span>{t('main.settings')}</span>
                    <FaCog size={20} color="white" />
                  </MobileActionButton>
                )}
                <MobileActionButton onClick={onLogoutClick}>
                  <span>{t('main.logout')}</span>
                  <FaSignOutAlt size={20} color="white" />
                </MobileActionButton>
              </div>
            </div>
          </div>
        )}
        <div className="d-flex align-items-center flex-grow-0 flex-shrink-0 flex-basis-auto right-container">
          {isAdmin && (
            <a
              href="!#"
              className="mr-4 d-none d-lg-block btn btn-link"
              onClick={e => {
                e.preventDefault();
                onSettingSchemaClick();
              }}
            >
              <GoSettings />
            </a>
          )}

          <a
            href="!#"
            className="business-name text-dark ml-3 d-none d-lg-block font-weight-normal"
            onClick={e => {
              e.preventDefault();
              onLogoutClick();
            }}
          >
            {t('main.logout')}
          </a>
        </div>
      </Navbar>
    </div>
  );
};

Nav.propTypes = {
  apps: PropTypes.array.isRequired,
  instanceIcon: PropTypes.string,
  instanceName: PropTypes.string,
  isAdmin: PropTypes.bool,
  loading: PropTypes.bool,
  // menuItems: PropTypes.array,
  onAppClick: PropTypes.func.isRequired,
  onLogoutClick: PropTypes.func.isRequired,
  onSettingSchemaClick: PropTypes.func.isRequired,
  selectedApp: PropTypes.shape({
    key: PropTypes.string,
    icon: PropTypes.string,
    name: PropTypes.string,
  }),
  version: PropTypes.shape({
    client: PropTypes.string,
    core: PropTypes.string,
  }),
};

Nav.defaultProps = {
  isAdmin: false,
  // menuItems: [],
  version: {},
  instanceIcon: '',
  instanceName: '',
  selectedApp: undefined,
  loading: undefined,
};

export default Nav;

const DropdownList = ({ optionsList, appKey }) => (
  <Dropdown.Menu>
    {optionsList?.length > 0 &&
      optionsList?.map(option =>
        option.entityKey ? (
          <Dropdown.Item
            bsPrefix="dropdown-container lg"
            as="div"
            key={option.key}
          >
            <Link to={`/apps/${appKey}/${option.entityKey}`}>
              <div className="dropdown-subheading ">
                <i className="far fa-file" />
                <p className="dropdown-subheading-label">{option.label}</p>
              </div>
            </Link>
          </Dropdown.Item>
        ) : (
          <div className="dropdown-group" key={option.key}>
            {option.label && (
              <Dropdown.Header className="dropdown-subheading">
                <i className={!option.icon ? 'far fa-file' : option.icon} />
                <p className="dropdown-subheading-label">{option.label}</p>
              </Dropdown.Header>
            )}
            {option.items.map(item => (
              <Dropdown.Item
                bsPrefix="dropdown-container lg"
                as="div"
                key={item.key}
              >
                <Link to={`/apps/${appKey}/${item.entityKey}`}>
                  {item.label}
                </Link>
              </Dropdown.Item>
            ))}
          </div>
        ),
      )}
  </Dropdown.Menu>
);

DropdownList.propTypes = {
  appKey: PropTypes.string,
  optionsList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      labelIcon: PropTypes.string,
      subItems: PropTypes.arrayOf(PropTypes.string),
    }),
  ).isRequired,
};
DropdownList.defaultProps = {
  appKey: '',
};
