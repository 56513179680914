/* eslint-disable jsx-a11y/media-has-caption */
import { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FiMinusCircle } from 'react-icons/fi';

import cx from 'classnames';
import PropTypes from 'prop-types';

import FileIcon from 'components/FileIcon/FileIcon';
import { useEntityContext } from 'contexts/EntityContext';

import './FileField.scss';
import ConfirmationModal from '../../ConfirmationModal/ConfirmationModal';
import FieldLabel from '../FieldLabel';

const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];

const FileField = ({ name, entityKey, entityId, defaultValue, fieldItem }) => {
  const [files, setFiles] = useState([]);
  const [showConfirmDeleteModal, setConfirmShowDeleteModal] = useState(false);
  const [progress, setProgress] = useState(0);
  const [entityValue, setEntityValue] = useState(defaultValue || undefined);
  const componentOptionValue = fieldItem.ui_component_options?.accept;
  const readOnly = fieldItem.ui_component_options?.readOnly || false;

  const {
    actions: { insertFileUpload },
  } = useEntityContext();

  const {
    register,
    unregister,
    getValues,
    setValue,
    errors,
  } = useFormContext();

  const onDrop = useCallback(acceptedFiles => setFiles(acceptedFiles), []);
  const { t } = useTranslation();

  const onDropAccepted = async uploads => {
    const data = new FormData();
    uploads.map(file => {
      data.append('files', file);
      return file;
    });
    const previousValue = getValues(name);
    setValue(name, undefined);
    setProgress(0);
    const response = await insertFileUpload(entityKey, name, data, event => {
      setProgress(Math.round((95 * event.loaded) / event.total));
    });
    setProgress(100);
    if (!response) {
      setValue(name, previousValue);
      return false;
    }

    setValue(name, JSON.stringify(response));
    return true;
  };

  const deleteFile = async () => {
    setFiles([]);
    setValue(name, '');
    setEntityValue(undefined);
    setConfirmShowDeleteModal(false);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropAccepted,
    accept: componentOptionValue,
    multiple: false,
  });

  useEffect(() => {
    register(name);
    setValue(name, defaultValue ? JSON.stringify(defaultValue) : '');
    return () => {
      unregister(name);
    };
  }, [register, unregister, name, setValue, defaultValue]);

  return (
    <FieldLabel fieldItem={fieldItem}>
      {!readOnly && (
        <div {...getRootProps()}>
          <input
            className={cx(
              'shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline',
              { 'is-invalid': errors[name] },
            )}
            id={name}
            {...getInputProps()}
          />
          <div
            className={`w-full p-2 border border-dashed border-gray-900 d-flex ${
              isDragActive ? 'bg-gray-400' : 'bg-gray-200'
            }`}
          >
            {defaultValue &&
              defaultValue?.isPublic &&
              ['image/gif', 'image/jpeg', 'image/png'].includes(
                defaultValue?.mimetype,
              ) && (
                <div className="pr-3">
                  <img
                    src={defaultValue.url}
                    alt={defaultValue.name}
                    style={{ height: '100px' }}
                  />
                </div>
              )}
            <div className="flex-grow-1 pt-4">
              {!readOnly && (
                <p className="text-center my-2 text-muted normal-text">
                  {t('entity.attachment.click-here')}
                </p>
              )}
              {/* Optionally you may display a preview of the file(s) */}
              {!!files?.length && (
                <div className="grid gap-1 grid-cols-4 mt-2">
                  {files.map(file => (
                    <div key={file.name} className="text-center mb-3">
                      {(!validImageTypes.includes(file.type) && (
                        <div>
                          <FileIcon type={file.type} />
                          &nbsp;{file.name}
                        </div>
                      )) || (
                        <img
                          className="thumbnailImage"
                          src={URL.createObjectURL(file)}
                          alt={file.name}
                        />
                      )}
                    </div>
                  ))}
                </div>
              )}
              <div className="progress">
                <div
                  className="progress-bar progress-bar-info progress-bar-striped"
                  role="progressbar"
                  aria-valuenow={progress}
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{ width: `${progress}%` }}
                >
                  {progress}%
                </div>
              </div>
            </div>
          </div>
          {fieldItem.description && (
            <small className="form-text text-muted">
              {fieldItem.description}
            </small>
          )}
        </div>
      )}
      {entityValue && (
        <div className="mt-2">
          <FileIcon type={entityValue.mimetype} />
          <a
            href={
              entityValue.isPublic
                ? entityValue.url
                : `/api/${entityKey}/${entityId}/storage/${name}`
            }
            className="ml-2 imageLink"
            target="_blank"
            rel="noopener noreferrer"
          >
            {entityValue.name}
          </a>
          {!readOnly && (
            <div className="float-right">
              <button
                type="button"
                className="btn btn-sm"
                onClick={() => setConfirmShowDeleteModal(true)}
              >
                <FiMinusCircle className="text-danger" />
              </button>
            </div>
          )}
        </div>
      )}
      {showConfirmDeleteModal && (
        <ConfirmationModal
          title={t('entity.modal.deleting-confirm-title')}
          body={t('entity.modal.deleting-confirm-file-message')}
          show={showConfirmDeleteModal}
          handleClose={() => setConfirmShowDeleteModal(false)}
          confirmAction={deleteFile}
          confirmType="danger"
        />
      )}
    </FieldLabel>
  );
};

FileField.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.any,
  entityKey: PropTypes.string.isRequired,
  entityId: PropTypes.number,
  fieldItem: PropTypes.shape({
    placeholder: PropTypes.string,
    description: PropTypes.string,
    ui_component_options: PropTypes.shape({
      accept: PropTypes.string,
      readOnly: PropTypes.bool,
    }),
  }).isRequired,
};

FileField.defaultProps = {
  defaultValue: '',
  entityId: 0,
};

export default FileField;
