import ContentLoader from 'react-content-loader';

import PropTypes from 'prop-types';

const OFFSET = 70;
const TableLoader = ({ lines, height, gutter, ...props }) => {
  let linesComponents = [];
  for (let i = 0; i < lines; i += 1) {
    linesComponents = [
      ...linesComponents,
      <circle key={1 + i * 6} cx="92.5%" cy={83 + i * OFFSET} r="11" />,
      <circle key={2 + i * 6} cx="96%" cy={83 + i * OFFSET} r="11" />,
      <rect
        key={3 + i * 6}
        x="7%"
        y={76 + i * OFFSET}
        rx="3"
        ry="3"
        width="15%"
        height="15"
      />,
      <rect
        key={4 + i * 6}
        x="26.5%"
        y={76 + i * OFFSET}
        rx="3"
        ry="3"
        width="40%"
        height="15"
      />,
      <rect
        key={5 + i * 6}
        x="70%"
        y={76 + i * OFFSET}
        rx="3"
        ry="3"
        width="18%"
        height="15"
      />,
      <rect
        key={6 + i * 6}
        x="0"
        y={117 + i * OFFSET}
        rx="3"
        ry="3"
        width="100%"
        height="2"
      />,
    ];
  }
  const viewBoxHeight = lines * 60 + 100;
  return (
    <ContentLoader
      height={viewBoxHeight}
      backgroundColor="#eaeced"
      foregroundColor="#ffffff"
      {...props}
    >
      <rect x="0" y="0" rx="3" ry="3" width="7%" height="33" />
      <rect x="22%" y="0" rx="3" ry="3" width="4.5%" height="33" />
      <rect x="66.5%" y="0" rx="3" ry="3" width="3.5%" height="33" />
      <rect x="88%" y="0" rx="3" ry="3" width="2%" height="33" />
      <rect x="98%" y="0" rx="3" ry="3" width="2%" height="33" />
      <rect x="0" y="0" rx="3" ry="3" width="100%" height="17" />
      <rect x="0" y="33" rx="3" ry="3" width="100%" height="17" />
      {linesComponents}
    </ContentLoader>
  );
};

TableLoader.propTypes = {
  lines: PropTypes.number,
  gutter: PropTypes.number,
  speed: PropTypes.number,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  backgroundColor: PropTypes.string,
  foregroundColor: PropTypes.string,
};

TableLoader.defaultProps = {
  lines: 6,
  speed: 2,
  gutter: 10,
  width: '100%',
  height: 550,
  backgroundColor: '#f3f3f3',
  foregroundColor: '#ecebeb',
};

export default TableLoader;
