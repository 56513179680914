export const translation_en = {
  entity: {
    main: {
      details: 'DETAILS',
      attachments: 'ATTACHMENTS',
      history: 'HISTORY',
      comments: 'COMMENTS',
    },
    filter: {
      'none-selected': '. . .',
      apply: 'Apply',
      'add-filter': 'Add Filters',
      'pick-field': 'Pick a field',
      blank: '(Blank)',
      select: 'Select ...',
    },
    buttons: {
      create: 'Create',
      export: 'Export',
      print: 'Print',
      'export-csv': 'Export CSV',
      'edit-record': 'Edit record',
      'create-record': 'Create record',
      'view-record': 'View record',
    },
    modal: {
      save: 'Save',
      create: 'Create',
      'create-new': 'Create new',
      edit: 'Edit',
      close: 'Close',
      'saving-confirm': 'Are you sure you want to close without saving?',
      cancel: 'Cancel',
      confirmation: 'Confirmation',
      confirm: 'Confirm',
      submit: 'Submit',
      reject: 'Reject',
      'close-without-save': 'Close without saving',
      'delete-record': 'Delete Record',
      'deleting-confirm-title': 'Delete confirmation',
      'deleting-confirm-message':
        'Are you sure you want to delete this record?',
      'deleting-confirm-file-message':
        'Are you sure you want to delete this file?',
      'more-info-title': 'More information required',
      'more-info-message':
        'Provide a reason why you want to perform this action.',
      'rejecting-confirm-title': 'Are you sure you want to reject this record?',
      'rejecting-confirm-message':
        'When rejecting this record, it will go into a read only state, and cannot be edited.',
      'approving-confirm-title': 'By proceeding this record will be approved.',
      'approving-confirm-message':
        'By proceeding, this record will be approved and will reach the end of its workflow.',
    },
    control: {
      'no-option': 'no options',
    },
    grid: {
      'no-row': 'No Rows To Show',
      'page-showing': 'Showing',
      'page-of': 'of',
    },
    comment: {
      'add-comment': 'Add a comment...',
      save: 'Save Comment',
      cancel: 'Cancel',
      unknown: 'Unknown',
    },
    history: {
      'attachment-uploaded': 'uploaded a new attachment',
      'attachment-downloaded': 'downloaded an attachment',
      'attachment-deleted': 'deleted an attachment',
      'entity-updated': 'updated the entity',
      'entity-created': 'created the entity',
      'user-assigned': 'user assigned',
      'record-rejected': 'rejected this record',
      'record-approved': 'approved this record',
      'record-picked': 'picked up this record',
      'record-backed': 'moved this record back',
      'record-assigned': 'assigned this record to',
    },
    attachment: {
      'upload-files': 'Upload files',
      'click-here': 'Click to select or drag and drop your file here...',
    },
    validation: {
      required: 'This field is required',
      'min-len': 'Min Length for this field is ',
      'max-len': 'Max Length for this field is ',
      min: 'The number must be greater than or equal to ',
      max: 'The number must be less than or equal to ',
      integer: 'The number must be integer',
      number: 'The value must be number',
      pattern: 'The text format is invalid',
    },
    view: {
      'view-record': 'View',
    },
  },
  main: {
    apps: 'Apps',
    'switch-apps': 'Switch Apps',
    settings: 'Settings',
    logout: 'Logout',
    signin: 'SIGN IN',
    update: 'CHANGE',
    copyright: 'POWERED BY JACSYS',
    tsum: 'TSUM SYSTEM',
    loading: 'Loading ...',
    'report-app-name': 'Reports',
  },
  settings: {
    entity: 'Entity',
    entities: 'Entities',
    'search-entities': 'Search entities',
    'entity-definition': ' Entity Definition',
    'related-entities': 'Related Entities',
    'form-layout': 'Form Layout',
    'with-workflow': '(WF)',
    'workflow-and-automation': 'Workflow and Automation',
    'data-table': 'Data Table',
    'printable-documents': 'Printable Documents',
    'fields-list': 'Fields List',
    'api-address':
      'Use the web address below to provide interoperability between computer systems.',
    fields: 'Fields',
    'associated-workflow': 'Associated Workflow',
    'no-associated-workflow': 'Entity has no associated workflow',
    hooks: 'Automation (Hooks)',
    'no-automation-workflow':
      'There are no automation workflows defined for this entity.',
    'grid-settings': 'Grid Settings',
    'export-settings': 'Export Settings',
    'no-printable-documents':
      'There are no printable documents defined for this entity.',
    'no-entities': 'There are no related entities for this entity.',
    'no-entities-found': 'No entities found',
  },
  message: {
    'entity-created': 'Record created',
    'entity-deleted': 'Record deleted',
    'entity-updated': 'Record updated',
    'entity-saved': 'Record saved',
    'comment-added': 'Your comment has been made',
    'attachment-inserted': 'Attachment added',
    'attachment-deleted': 'Attachment has been deleted',
  },
};
