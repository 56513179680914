import { formatDateToString } from 'utils/dateUtils';

import FileRenderer from 'components/Entity/EntityTable/renderers/FileRenderer';

import WorkflowRenderer from '../components/Entity/EntityTable/renderers/WorkflowRenderer';

const buildRelatedGridOptions = (
  agGridOptions,
  schema,
  appSettings,
  entityKey,
) => {
  const newGridOptions = { ...agGridOptions };
  if (newGridOptions?.columnDefs && schema) {
    newGridOptions.columnDefs = newGridOptions.columnDefs.map(columnDef => {
      const newColumnDef = {
        ...columnDef,
      };
      const field = schema.fields[columnDef.field];
      const isNativeDateField =
        columnDef.field === '_created_at' || columnDef.field === '_updated_at';

      newGridOptions.frameworkComponents = {
        ...(newGridOptions.frameworkComponents || {}),
        fileRenderer: FileRenderer,
      };

      if (field?.db_type === 'datetime' || isNativeDateField) {
        const { time } = field?.ui_component_options || { time: true };
        newColumnDef.cellRenderer = data => {
          if (!data.value) {
            return '';
          }

          return formatDateToString(appSettings, data.value, time);
        };
      }

      if (field?.db_type === 'boolean') {
        newColumnDef.cellRenderer = data => (data.value ? 'Тийм' : 'Үгүй');
      }

      if (field?.db_type === 'json' && field?.ui_component === 'FileField') {
        newColumnDef.cellRenderer = 'fileRenderer';
      }

      return newColumnDef;
    });

    if (!newGridOptions.columnDefs.find(col => col.field === 'id')) {
      newGridOptions.columnDefs.splice(0, 0, {
        headerName: '#',
        field: 'id',
        width: 90,
        maxWidth: 90,
        sortable: true,
      });
    }

    if (schema?.hasWorkflow) {
      newGridOptions.frameworkComponents = {
        ...(newGridOptions.frameworkComponents || {}),
        workflowRenderer: WorkflowRenderer,
      };

      newGridOptions.columnDefs.splice(1, 0, {
        headerName: 'Воркфлов',
        field: '_workflow_current_step',
        sortable: true,
        cellRenderer: 'workflowRenderer',
        cellStyle: { overflow: 'visible' },
        cellRendererParams: { entityKey },
      });
    }
  }

  return newGridOptions;
};

export default buildRelatedGridOptions;
