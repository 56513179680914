import api from 'core/api';
// import { print } from 'graphql/language/printer';

// const getToken = () => {
//   const token = localStorage.getItem('token');

//   if (token) {
//     return `Bearer ${localStorage.getItem('token')}`;
//   }

//   return null;
// };

const getHeaders = () => ({
  'Content-Type': 'application/json',
  // Authorization: getToken(),
});

const fetch = async ({
  url,
  data,
  method = 'GET',
  headers,
  responseType,
  onProgress,
}) => {
  const res = await api({
    url,
    method,
    headers: headers || getHeaders(),
    data,
    responseType,
    onUploadProgress: onProgress,
  }).catch(err => {
    throw err.response.data;
  });
  // res.status

  return res.data;
};
/**
 * METHODS
 */
const get = async url => fetch({ url });

const post = async (url, data, headers, onProgress) =>
  fetch({ url, data, method: 'POST', headers, onProgress });

const download = async (url, data, headers) =>
  fetch({ url, data, method: 'POST', headers, responseType: 'blob' });

const put = async (url, data) => fetch({ url, data, method: 'PUT' });

const remove = async (url, data) => fetch({ url, data, method: 'DELETE' });

// eslint-disable-next-line no-unused-vars
const graphql = async (query, variables = {}) => {
  // const data = print(query);
  // return fetch({
  //   url: '/',
  //   data: {
  //     query: data,
  //     variables,
  //   },
  //   method: 'POST',
  // });
};

export { get, post, put, remove, graphql, download };

export default fetch;
