import { useEffect, useState } from 'react';
import { FiTrash2, FiDownload } from 'react-icons/fi';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';
import { Messages } from 'utils/messages';

import Dropzone from 'components/Dropzone/Dropzone';
import FileIcon from 'components/FileIcon/FileIcon';
import { IronBody } from 'components/Layout';
import Moment from 'components/ThirdLibraries/Moment/Moment';
import { useEntityContext } from 'contexts/EntityContext';

import AttachmentLoader from '../../Loaders/AttachmentLoader';

const EntityAttachment = ({ entityKey, entityId }) => {
  const [loading, setLoading] = useState(false);
  const [insertLoading, setInsertLoading] = useState(false);
  const {
    getEntityState,
    actions: {
      fetchEntityAttachments,
      insertEntityAttachment,
      deleteEntityAttachment,
    },
  } = useEntityContext();

  const entityState = getEntityState(entityKey, entityId);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await fetchEntityAttachments(entityKey, entityId);
      } catch (error) {
        toast.error(error);
      }
      setLoading(false);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async files => {
    const data = new FormData();
    files.map(file => {
      data.append('files', file);
      return file;
    });
    setInsertLoading(true);
    try {
      const response = await insertEntityAttachment(entityKey, data, entityId);
      if (!response) {
        throw new Error('No response');
      }
    } catch (error) {
      toast.error(error);
      setInsertLoading(false);
      return false;
    }

    try {
      await fetchEntityAttachments(entityKey, entityId);
    } catch (error) {
      toast.error(error);
    }
    setInsertLoading(false);
    toast.success(Messages.AttachmentInserted);
    return true;
  };

  const onDelete = async attachment => {
    const success = await deleteEntityAttachment(
      entityKey,
      entityId,
      attachment.id,
    );

    if (success) {
      toast.success(Messages.AttachmentDeleted);

      fetchEntityAttachments(entityKey, entityId);
    }
  };

  return (
    <IronBody>
      <Dropzone onSubmit={onSubmit} />
      <div className="mt-5">
        {loading ? (
          <AttachmentLoader />
        ) : (
          entityState?.attachments?.length > 0 && (
            <table className="table mb-0">
              <tbody>
                {entityState.attachments.map(attachment => (
                  <tr key={attachment.id}>
                    <td width="5%">
                      <FileIcon type={attachment.mimetype} size="fa-3x" />
                    </td>

                    <td>
                      <span>{attachment.name}</span>
                      <br />
                      <span className="text-muted">
                        <Moment fromNow>{attachment._created_at}</Moment>
                      </span>
                    </td>
                    <td>
                      <a
                        href={`/api/${attachment.key}/${attachment.entity_id}/attachment/${attachment.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ml-md-4 btn btn-link"
                      >
                        <FiDownload className="text-primary" />
                      </a>

                      <button
                        className="ml-md-3 btn btn-link"
                        onClick={() => onDelete(attachment)}
                        type="button"
                      >
                        <FiTrash2 className="text-primary" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )
        )}
        {insertLoading && <AttachmentLoader rows={1} />}
      </div>
    </IronBody>
  );
};

EntityAttachment.propTypes = {
  entityKey: PropTypes.string.isRequired,
  entityId: PropTypes.number.isRequired,
};

export default EntityAttachment;
