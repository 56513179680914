import PropTypes from 'prop-types';

const WorkflowInfo = ({ workflow }) => (
  <div className="d-flex align-items-center" data-cy="workflow-info">
    {workflow && workflow.ownerName && (
      <div
        className="ml-1 text-white font-weight-lighter"
        data-cy="workflow-assigned-to"
      >
        Ажилд томилогдсон{' '}
        <strong className="ml-1 font-weight-normal">
          {workflow && workflow.ownerName}
        </strong>
      </div>
    )}
  </div>
);

WorkflowInfo.propTypes = {
  workflow: PropTypes.shape({
    ownerName: PropTypes.string,
    outcome: PropTypes.number,
    definition: PropTypes.any,
    currentStep: PropTypes.number,
  }).isRequired,
};

export default WorkflowInfo;
