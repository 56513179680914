import { useState } from 'react';

import PropTypes from 'prop-types';

import FormLayout from './FormLayout';

const FormLayoutWithWorkflow = ({ schema }) => {
  const [workflowStep, setWorkflowStep] = useState();
  return (
    <>
      <div className="d-flex align-items-baseline">
        <div className="mr-3">
          <h5>Form layout for step:</h5>
        </div>
        <div>
          <select
            className="mb-4 form-control px-2"
            onChange={e => {
              const step = schema.workflow.schemaByStep[e.target.value];
              setWorkflowStep(step);
            }}
          >
            <option>New</option>
            {schema.workflow.schemaByStep.map((step, idx) => {
              const stepDef = schema.workflow.definition.steps[idx];
              return (
                <option key={stepDef.key} value={idx}>
                  {stepDef.name}
                </option>
              );
            })}
          </select>
        </div>
      </div>

      <FormLayout
        formLayout={workflowStep?.formLayout || schema.formLayout}
        fields={workflowStep?.fields || schema.fields}
      />
    </>
  );
};

FormLayoutWithWorkflow.propTypes = {
  schema: PropTypes.object.isRequired,
};

export default FormLayoutWithWorkflow;
