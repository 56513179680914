import { useContext } from 'react';
import './Login.scss';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../contexts/AppContext';

const TSUM_API = process.env.REACT_APP_JACSYS_TSUM_API;

const Login = () => {
  const {
    state: { settings },
  } = useContext(AppContext);
  const { t } = useTranslation();
  return (
    <div
      style={{
        backgroundImage: `url(${settings.loginBackgroundImage})`,
      }}
      className="Login d-flex align-items-center justify-content-center flex-column flex-grow-1 m-0 w-100"
    >
      <Card className="login-card">
        <Card.Body className="p-5">
          <img
            className="company-logo"
            src={settings.companyLogo}
            alt={settings.name}
          />
          <h4 className="my-3">{settings.name}</h4>
          <div className="px-4">
            <a
              className="btn btn-block btn-primary"
              href={`${TSUM_API}/auth/login`}
            >
              {t('main.signin')}
            </a>
          </div>
          <hr className="my-4" />
          <p className="m-0 letter-spacig-lg">{t('main.copyright')}</p>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Login;
