import { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { IoCloudUploadOutline, AiOutlineLink } from 'react-icons/all';

import PropTypes from 'prop-types';

import FileIcon from 'components/FileIcon/FileIcon';

import './Dropzone.scss';

function Dropzone({ onSubmit }) {
  const [files, setFiles] = useState([]);
  const { t } = useTranslation();

  const onDrop = useCallback(
    acceptedFiles => setFiles(prevState => [...prevState, ...acceptedFiles]),
    [],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  return (
    <div className="row">
      <div className="col-12">
        <div {...getRootProps({ className: 'Dropzone' })}>
          <input {...getInputProps()} />

          <button type="button" className="btn btn-primary">
            <IoCloudUploadOutline className="mr-2" />
            {t('entity.attachment.upload-files')}
          </button>
        </div>
      </div>

      {files?.length > 0 && (
        <>
          <div className="col-12 col-md-4">
            <h5 className="mt-2 mt-md-0">Files to upload:</h5>
            <ul className="list-unstyled">
              {files.map(file => (
                <li key={file.path}>
                  <FileIcon type={file.type} />{' '}
                  {file.name.substr(0, file.name.lastIndexOf('.'))}
                </li>
              ))}
            </ul>
          </div>

          <div className="col-12 col-md-3">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                const response = onSubmit(files);
                if (response) {
                  setFiles([]);
                }
              }}
            >
              <AiOutlineLink />
              {` `} Attach files
            </button>
          </div>
        </>
      )}
    </div>
  );
}
Dropzone.propTypes = {
  onSubmit: PropTypes.func,
};
Dropzone.defaultProps = {
  onSubmit: () => {},
};
export default Dropzone;
