import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { get } from 'core/fetch';
import PropTypes from 'prop-types';
import buildRelatedGridOptions from 'utils/buildRelatedGridOptions';

import { AgGrid } from 'components/ThirdLibraries';
import { AppContext } from 'contexts/AppContext';
import { useDialogManager } from 'contexts/DialogManagerContext';

import FieldLabel from './FieldLabel';

const Grid = ({ defaultValue, fieldItem, entityKey, entityId }) => {
  const {
    state: { settings, selectedApp },
  } = useContext(AppContext);

  const { ui_component_options: componentOptions } = fieldItem;
  const { relationshipField, allowCreate } = fieldItem.ui_component_options;
  const { related_entity, name: relation_name } = fieldItem.relationshipOptions;
  const [gridItems, setGridItems] = useState(defaultValue);
  const { openDialog } = useDialogManager();
  const { t } = useTranslation();

  const entitySchema = fieldItem.schema;

  if (!entitySchema) {
    return <div />;
  }

  const {
    columnDefs,
    defaultColDef,
    suppressHorizontalScroll,
    frameworkComponents,
  } = buildRelatedGridOptions(
    componentOptions.gridSettings.agGrid,
    entitySchema,
    settings,
    related_entity,
  );

  const afterActionCallback = async () => {
    const response = await get(`/${entityKey}/${entityId}`);

    setGridItems(response[relation_name]);
  };

  return (
    <FieldLabel
      fieldItem={fieldItem}
      actions={
        <div className="text-right">
          {allowCreate && (
            <button
              className="btn btn-outline-secondary btn-sm"
              type="button"
              disabled={!entityId}
              title={
                !entityId
                  ? `Please create the ${entityKey} first, to start adding ${fieldItem.label}`
                  : ''
              }
              onClick={() => {
                openDialog({
                  entityKey: related_entity,
                  afterSaveCallback: afterActionCallback,
                  afterDeleteCallback: afterActionCallback,
                  foreignKey: {
                    fieldName: relationshipField,
                    fieldValue: entityId,
                  },
                });
              }}
            >
              <i className="fa fa-plus" />
              {t('entity.buttons.create')}
              {` `}
              {fieldItem.label}
            </button>
          )}
          <button
            className="btn btn-link btn-sm"
            type="button"
            disabled={!entityId}
            onClick={() => {
              window.open(`/apps/${selectedApp}/${related_entity}`, '_blank');
            }}
          >
            <i className="fa fa-external-link" />
          </button>
        </div>
      }
    >
      <AgGrid
        rowData={gridItems || []}
        columnDefs={columnDefs}
        onRowSelected={event => {
          if (!event.node.selected) return;

          openDialog({
            entityKey: related_entity,
            entityId: parseInt(event.node.data.id, 10),
            afterSaveCallback: afterActionCallback,
            afterDeleteCallback: afterActionCallback,
            foreignKey: {
              fieldName: relationshipField,
              fieldValue: entityId,
            },
          });

          // Unselect row selected, so I can re-select the same again
          event.node.setSelected(false);
        }}
        frameworkComponents={frameworkComponents}
        defaultColDef={defaultColDef}
        suppressHorizontalScroll={suppressHorizontalScroll}
      />

      {fieldItem.description && (
        <small className="form-text text-muted">{fieldItem.description}</small>
      )}

      {gridItems.length >= 100 && (
        <small className="form-text text-danger">
          This grid might not show all records. it's been limited to 100
          records.
        </small>
      )}
    </FieldLabel>
  );
};

Grid.propTypes = {
  defaultValue: PropTypes.any,
  fieldItem: PropTypes.shape({
    key: PropTypes.any,
    schema: PropTypes.any,
    placeholder: PropTypes.string,
    description: PropTypes.string,
    label: PropTypes.string,
    ui_component_options: PropTypes.shape({
      componentOptions: PropTypes.PropTypes.shape({
        gridSettings: PropTypes.object.isRequired,
      }),
      relationshipField: PropTypes.string,
      allowCreate: PropTypes.bool,
    }),
    relationshipOptions: PropTypes.shape({
      related_entity: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  }).isRequired,
  entityKey: PropTypes.string,
  entityId: PropTypes.number,
};

Grid.defaultProps = {
  defaultValue: '',
  entityKey: '',
  entityId: undefined,
};

export default Grid;
