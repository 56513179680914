import { useState } from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { FaSpinner } from 'react-icons/fa';
import MonacoEditor from 'react-monaco-editor';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import { AgGrid } from 'components/ThirdLibraries';

import { useEntityContext } from '../../../contexts/EntityContext';
import './Hooks.scss';

const ActionButtonRenderer = ({ data }) => {
  const [hookData, setHookData] = useState();
  const [loading, setLoading] = useState();
  const {
    actions: { fetchEntityHookContent },
  } = useEntityContext();

  const openDialogWithHookDetails = async () => {
    try {
      setLoading(true);
      const hookDataResponse = await fetchEntityHookContent(data.id);
      setHookData(hookDataResponse);
    } catch (error) {
      toast.error(error);
    }
    setLoading(false);
  };

  const PREVIEW_HEIGHT = 600;
  const editorOptions = {
    lineHeight: 20,
    automaticLayout: true,
    renderLineHighlight: 'all',
    minimap: { enabled: false },
    autoIndent: true,
    renderFinalNewline: true,
    wordWrap: 'on',
    wordWrapColumn: 80,
    readOnly: false,
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-end py-1">
        <Button
          disabled={loading}
          onClick={async ev => {
            ev.stopPropagation();
            await openDialogWithHookDetails();
          }}
        >
          {loading && <FaSpinner className="spinnner mr-2" icon="spinner" />}
          Харах
        </Button>
      </div>
      <Modal
        size="xl"
        show={!!hookData}
        dialogClassName="hookModal"
        onHide={() => setHookData(undefined)}
      >
        <Modal.Header className="border-0" closeButton>
          <Modal.Title>
            <h5 className="text-center text-dark pt-3">
              #{data.id}
              {' - '}
              {data.type === 'function'
                ? 'Custom Code'
                : 'Automation Design'}{' '}
              {data.function_name && `- ${data.function_name}.js`}
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-4 pb-4">
          {hookData?.code && (
            <div className="w-100">
              <MonacoEditor
                options={editorOptions}
                height={PREVIEW_HEIGHT}
                language="javascript"
                // theme="vs-dark"
                initialValue={hookData.code}
                value={hookData.code}
              />
            </div>
          )}
          {hookData?.workflow_id && (
            <iframe
              className="automation-preview"
              title="workflow"
              src={`/workflow_engine/workflow/${hookData?.workflow_id}`}
              frameBorder="0"
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

ActionButtonRenderer.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    function_name: PropTypes.string,
  }).isRequired,
};

const Hooks = ({ hooks = [] }) => (
  <AgGrid
    frameworkComponents={{
      actionButtonRenderer: ActionButtonRenderer,
    }}
    rowData={hooks}
    columnDefs={[
      {
        headerName: 'Identifier',
        field: 'function_name',
        maxWidth: 200,
        sortable: false,
        valueFormatter: ({ data, value }) => {
          if (data.type === 'workflow') {
            return `Automation Design #${data.workflow_id}`;
          }
          if (data.type === 'function') {
            return data.function_name;
          }
          return value;
        },
      },
      {
        headerName: 'Lifecycle',
        field: 'hook_type',
        maxWidth: 240,
        sortable: false,
        valueFormatter: ({ data, value }) => {
          let message = '';
          if (value === 'ValidateAndTransformInput') {
            message = 'On Validating';
          }
          if (value === 'AfterChange') {
            message = 'After Saving';
          }
          if (data.action_key) {
            message += ` (${data.action_key})`;
          }

          if (data.operation) {
            message += ` (${data.operation})`;
          }

          return message;
        },
      },
      {
        headerName: 'Engine',
        field: 'type',
        maxWidth: 180,
        sortable: true,
        valueFormatter: ({ value }) => {
          if (value === 'workflow') {
            return 'Workflow Designer';
          }
          if (value === 'function') {
            return 'Custom Code (JavaScript)';
          }
          return value;
        },
      },
      {
        headerName: 'Description',
        field: 'description',
        sortable: true,
      },
      {
        maxWidth: 120,
        headerName: '',
        field: 'id',
        sortable: true,
        cellRenderer: 'actionButtonRenderer',
        cellStyle: { overflow: 'visible' },
      },
    ]}
  />
);

Hooks.propTypes = {
  hooks: PropTypes.array.isRequired,
};

export default Hooks;
