import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';

import { AgGrid } from 'components/ThirdLibraries';
import {
  RelationshipFields,
  RelationshipFieldsTypes,
} from 'constants/fieldTypes';

const RelatedEntityFields = ({ fields }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const relatedFields = Object.values(fields).filter(value =>
    RelationshipFields.includes(value.ui_component),
  );

  if (relatedFields.length === 0) {
    return t('settings.no-entities');
  }

  const columnDefs = [
    {
      headerName: 'Entity',
      field: 'relationshipOptions.related_entity',
      sortable: true,
    },
    {
      headerName: 'Relationship Type',
      field: 'ui_component',
      sortable: true,
      cellRenderer: row => `<div>${RelationshipFieldsTypes[row.value]}</div>`,
    },
  ];

  const onCellClicked = event => {
    const { related_entity } = event.data.relationshipOptions;

    history.push(`/settings/schemas/${related_entity}`);
  };

  return (
    <AgGrid
      rowData={relatedFields}
      columnDefs={columnDefs}
      onCellClicked={onCellClicked}
    />
  );
};

RelatedEntityFields.propTypes = {
  fields: PropTypes.object.isRequired,
};

export default RelatedEntityFields;
