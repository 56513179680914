import {
  FaList,
  FaMapMarkerAlt,
  FaThLarge,
  FaRegCalendarAlt,
} from 'react-icons/fa';

const VIEWS = Object.freeze({
  LIST: 'listView',
  MAP: 'mapView',
  KANBAN: 'kanbanBoardView',
  TIMELINE: 'timelineView',
});

const VIEWS_ICONS = {
  [VIEWS.LIST]: <FaList />,
  [VIEWS.MAP]: <FaMapMarkerAlt />,
  [VIEWS.KANBAN]: <FaThLarge />,
  [VIEWS.TIMELINE]: <FaRegCalendarAlt />,
};

export default { VIEWS, VIEWS_ICONS };
