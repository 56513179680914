import { Fragment } from 'react';

import PropTypes from 'prop-types';

const ErrorAlert = ({ error, className = 'badge badge-danger text-wrap' }) => (
  <>
    {error && (
      <>
        <div className={className} role="alert">
          {typeof error === 'string' ? (
            <span>{error}</span>
          ) : (
            <>
              {error?.length > 0 &&
                error?.map(err => (
                  <Fragment key={`${err.message}`}>
                    <strong>{err.messageType}</strong>
                    <p className="mb-0">{err.message}</p>
                    <br />
                  </Fragment>
                ))}
            </>
          )}
        </div>
      </>
    )}
  </>
);

ErrorAlert.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  className: PropTypes.string,
};

ErrorAlert.defaultProps = {
  className: undefined,
};

export default ErrorAlert;
