import { useState, useEffect, useCallback, useMemo } from 'react';
import { Card, Button, Dropdown } from 'react-bootstrap';
import { createPortal } from 'react-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BsThreeDotsVertical } from 'react-icons/bs';
import {
  FaSpinner,
  FaUserCheck,
  FaChevronLeft,
  FaChevronRight,
  FaThumbsUp,
  FaThumbsDown,
} from 'react-icons/fa';
import { IoMdPrint } from 'react-icons/io';

import { joiResolver } from '@hookform/resolvers';
import cx from 'classnames';
import { FormMode } from 'core/formMode';
import { each, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import prepareFormData from 'utils/prepareFormData';

import WorkflowMessage from 'components/WorkflowMessage/WorkflowMessage';
import { useAuthContext } from 'contexts/AuthContext';
import { useDialogManager } from 'contexts/DialogManagerContext';
import { useEntityContext } from 'contexts/EntityContext';

import usePortal from '../../../hooks/usePortal';
import { getOutcomeLabel } from '../../../utils/worflowUtils';
import ConfirmationExitModal from '../../ConfirmationExitModal/ConfirmationExitModal';
import FormLoader from '../../Loaders/FormLoader';
import Overlay from '../../Overlay/Overlay';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import Fieldsets from '../Fieldsets/Fieldsets';
import WorkflowReasonModal from '../WorkflowReason/WorkflowReasonModal';

import './EntityForm.module.scss';

const EntityMainActions = ({
  entityKey,
  entityId,
  mode,
  schema,
  menuActions,
  loading,
}) => {
  const availablePrintableDocs = schema?.printableDocuments?.filter(
    p => !p.use_in_grid,
  );
  const { t } = useTranslation();
  const { getEntityState } = useEntityContext();
  const entityState = getEntityState(entityKey, entityId);

  const workflow = schema?.workflow;
  const currentStep = useMemo(
    () =>
      workflow?.definition?.steps
        ? workflow.definition.steps.find(
            step => step.order === workflow.currentStep,
          )
        : undefined,
    [workflow],
  );

  return (
    <>
      <Button
        type="button"
        className="m-1"
        data-cy="save"
        disabled={mode === FormMode.readonly || !!loading}
        onClick={menuActions.save}
      >
        {loading ? (
          <FaSpinner color="white" className="fa-spin mr-2" />
        ) : (
          <i className="far fa-save mr-2" />
        )}
        {t('entity.modal.save')}
      </Button>

      {mode !== FormMode.create && (
        <>
          {entityState?.schema?.hasWorkflow && (
            <Dropdown>
              <Dropdown.Toggle
                data-cy="actions-dropdown"
                disabled={schema?.workflow?.availableSteps?.length === 0}
                variant={cx({
                  'outline-success': workflow.outcome === 1,
                  'outline-danger': workflow.outcome === 2,
                  'outline-warning': !workflow.outcome,
                })}
                id="dropdown-basic"
              >
                {getOutcomeLabel(workflow.definition, workflow.outcome) ||
                  currentStep?.name}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {schema.workflow.availableSteps.map(action => (
                  <Dropdown.Item
                    data-cy={`action-${action.key}`}
                    key={action.key}
                    disabled={loading}
                    onClick={() => menuActions.move(action)}
                  >
                    {action.to.takeOwnership && (
                      <FaUserCheck className="mr-2" />
                    )}

                    {action.to.reasonRequired && action.to.step && (
                      <FaChevronLeft className="mr-2" />
                    )}

                    {!action.to.reasonRequired && action.to.step && (
                      <FaChevronRight className="mr-2" />
                    )}

                    {action.to.outcome === 1 && <FaThumbsUp className="mr-2" />}
                    {action.to.outcome === 2 && (
                      <FaThumbsDown className="mr-2" />
                    )}

                    <span>{action.name}</span>
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          )}

          {availablePrintableDocs?.length > 0 && (
            <Dropdown>
              <Dropdown.Toggle
                id="btn-print"
                data-cy="print"
                variant="light"
                className="m-1"
                disabled={loading}
              >
                <IoMdPrint className="ml-2" title={t('entity.buttons.print')} />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {availablePrintableDocs.map(doc => (
                  <Dropdown.Item
                    disabled={loading}
                    key={doc.id}
                    href={`/api/${entityKey}/${entityId}/doc/${doc.id}`}
                    target="_blank"
                  >
                    {doc.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </>
      )}

      {mode === FormMode.edit && (
        <Dropdown
          className="h-100 mb-auto mt-auto nav-item nav-more-options dropdown-no-icon"
          data-cy="nav-more-options"
          variant="dark"
        >
          <Dropdown.Toggle variant="white">
            <BsThreeDotsVertical className="text-secondary" />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              data-cy="delete"
              active={false}
              href="#/action-1"
              onClick={menuActions.delete}
            >
              {t('entity.modal.delete-record')}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )}
      {entityState?.schema?.hasWorkflow &&
        entityState?.schema?.workflow?.outcomeReason && (
          <div className="mb-3">
            <WorkflowMessage workflow={entityState?.schema?.workflow} />
          </div>
        )}
    </>
  );
};

EntityMainActions.propTypes = {
  entityKey: PropTypes.string.isRequired,
  entityId: PropTypes.number,
  mode: PropTypes.string.isRequired,
  schema: PropTypes.any,
  loading: PropTypes.any,
  menuActions: PropTypes.shape({
    move: PropTypes.func.isRequired,
    save: PropTypes.func.isRequired,
    delete: PropTypes.func.isRequired,
    takeOwnership: PropTypes.func.isRequired,
  }).isRequired,
};

EntityMainActions.defaultProps = {
  schema: undefined,
  entityId: undefined,
  loading: undefined,
};

const WrapperComponent = ({ children }) => (
  <Card className="m-4 border-0 shadow-sm">
    <Card.Body>{children}</Card.Body>
  </Card>
);

WrapperComponent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

const EntityForm = ({
  entityState,
  formMode,
  entityId,
  entityKey,
  entityActionBarRef,
}) => {
  const target = usePortal(entityActionBarRef);
  const { getDialog, closeDialog, setDialogParam } = useDialogManager();

  const {
    state: { authenticatedUser },
  } = useAuthContext();

  const { actions } = useEntityContext();
  const { t } = useTranslation();

  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [showReasonModal, setShowReasonModal] = useState(false);
  const [showRejectReasonModal, setShowRejectReasonModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [showConfirmExitModal, setShowConfirmExitModal] = useState(false);
  const [stepTo, setStepTo] = useState();

  const formMethods = useForm({
    resolver: values =>
      joiResolver(entityState?.schema?.formValidation)(values),
  });

  const {
    formState: { dirtyFields },
  } = formMethods;

  const closeForm = useCallback(() => {
    const isDirty = Object.keys(dirtyFields).length > 0;
    if (isDirty) {
      setShowConfirmExitModal(true);
      return false;
    }

    closeDialog();
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setDialogParam({
      beforeHideCallback: closeForm,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (entityState?.isLoading) {
    return (
      <WrapperComponent>
        <FormLoader />
      </WrapperComponent>
    );
  }

  const entityFormSubmit = async (
    data,
    overrideAction = undefined,
    step = undefined,
  ) => {
    const transformedData = prepareFormData(entityState?.schema?.fields, data);

    const action = overrideAction ? actions[overrideAction] : actions[formMode];

    setLoadingSubmit(true);
    const response = await action(entityKey, transformedData, entityId, step);
    setLoadingSubmit(false);

    if (response === undefined) {
      // CLEMPE - error discoverability (developer is not returning fetch response)
      throw new Error('System could not determine fetch response');
    }

    // TODO: this will solve 90% of the cases, just just when server doesn't return a payload with error
    if (response?.error) {
      return;
    }

    const dialog = getDialog();
    if (dialog) {
      closeDialog(dialog.id);
      if (dialog.params?.afterSaveCallback) {
        dialog.params.afterSaveCallback({
          ...{ id: entityId }, // when updating, add entity id.
          ...response,
        });
      }
    }
  };

  const validateForm = async (formSchema, data) => {
    const { values, errors } = await joiResolver(formSchema)(data);

    if (!isEmpty(errors)) {
      each(Object.keys(errors), key => {
        formMethods.setError(key, errors[key]);
      });

      return undefined;
    }

    return values;
  };

  const save = async () => {
    if (formMode === FormMode.edit) {
      if (entityState?.schema.hasWorkflow) {
        const values = await validateForm(
          entityState?.schema.draftValidation,
          formMethods.getValues(),
        );

        if (!values) {
          return;
        }

        // draft save
        entityFormSubmit(values);
        return;
      }

      formMethods.handleSubmit(entityFormSubmit)();
      return;
    }

    formMethods.handleSubmit(entityFormSubmit)();
  };

  const takeOwnership = async () => {
    const values = await validateForm(
      entityState?.schema.draftValidation,
      formMethods.getValues(),
    );

    if (!values) {
      return;
    }

    entityFormSubmit(
      {
        ...values,
        workflow_owner: authenticatedUser.user_id,
      },
      'takeOwnership',
    );
  };

  const deleteEntity = () => {
    setShowConfirmDeleteModal(true);
  };

  const confirmDelete = async () => {
    const response = await actions.deleteEntity(entityKey, entityId);
    if (response?.error) return;

    setShowConfirmDeleteModal(false);

    const dialog = getDialog();
    if (dialog) {
      closeDialog(dialog.id);
      dialog.params.afterDeleteCallback({
        ...response,
        ...{ id: entityId },
      });
    }
  };

  const move = async ({ key, to }) => {
    if (to.takeOwnership) {
      takeOwnership();
      return;
    }

    const values = await validateForm(
      to.fullValidationRequired || to.outcome === 1
        ? entityState?.schema.formValidation
        : entityState?.schema.draftValidation,
      formMethods.getValues(),
    );

    if (!values) {
      return;
    }

    if (to.outcome === 1) {
      setStepTo(key);
      setShowApproveModal(true);
      return;
    }

    if (to.outcome === 2) {
      setStepTo(key);
      setShowRejectReasonModal(true);
      return;
    }

    if (to.reasonRequired) {
      setStepTo(key);
      setShowReasonModal(true);
      return;
    }

    formMethods.handleSubmit(data => entityFormSubmit(data, 'moveStep', key))();
  };

  const menuActions = {
    save,
    delete: deleteEntity,
    takeOwnership,
    move,
  };

  const portalElement = createPortal(
    <>
      <div className="d-flex align-items-center">
        <EntityMainActions
          entityKey={entityKey}
          entityId={entityId}
          mode={formMode}
          formData={entityState?.formData}
          schema={entityState?.schema}
          menuActions={menuActions}
          loading={loadingSubmit}
        />
      </div>
    </>,
    target,
  );

  const modals = (
    <>
      {showReasonModal && (
        <WorkflowReasonModal
          show={showReasonModal}
          handleClose={() => {
            setShowReasonModal(false);
          }}
          title={t('entity.modal.more-info-title')}
          body={t('entity.modal.more-info-message')}
          confirmActionText={t('entity.modal.submit')}
          confirmAction={reasonData => {
            const data = formMethods.getValues();

            setShowReasonModal(false);
            entityFormSubmit({ ...data, ...reasonData }, 'moveStep', stepTo);
          }}
        />
      )}
      {showRejectReasonModal && (
        <WorkflowReasonModal
          show={showRejectReasonModal}
          handleClose={() => {
            setShowRejectReasonModal(false);
          }}
          title={t('entity.modal.rejecting-confirm-title')}
          body={t('entity.modal.rejecting-confirm-message')}
          confirmActionText={t('entity.modal.reject')}
          confirmAction={reasonData => {
            const data = formMethods.getValues();

            setShowRejectReasonModal(false);
            entityFormSubmit({ ...data, ...reasonData }, 'moveStep', stepTo);
          }}
        />
      )}

      {showApproveModal && (
        <ConfirmationModal
          title={t('entity.modal.approving-confirm-title')}
          body={t('entity.modal.approving-confirm-message')}
          show={showApproveModal}
          handleClose={() => setShowApproveModal(false)}
          confirmAction={() => {
            const data = formMethods.getValues();

            setShowApproveModal(false);
            entityFormSubmit(data, 'moveStep', stepTo);
          }}
          confirmType="primary"
        />
      )}

      {showConfirmExitModal && (
        <ConfirmationExitModal
          centered
          modalLevel={50}
          show={showConfirmExitModal}
          onHide={() => {
            setShowConfirmExitModal(false);
          }}
          onCancel={() => setShowConfirmExitModal(false)}
          onConfirm={() => {
            closeDialog();
          }}
          size="md"
        />
      )}

      {showConfirmDeleteModal && (
        <ConfirmationModal
          title={t('entity.modal.deleting-confirm-title')}
          body={t('entity.modal.deleting-confirm-message')}
          show={showConfirmDeleteModal}
          handleClose={() => setShowConfirmDeleteModal(false)}
          confirmAction={confirmDelete}
          confirmType="danger"
        />
      )}
    </>
  );

  return (
    <div className="position-relative">
      {loadingSubmit && <Overlay />}
      <FormProvider {...formMethods}>
        {entityState?.schema && (
          <Fieldsets
            fields={entityState.schema.fields}
            fieldsets={entityState.schema.formLayout.fieldsets}
            data={entityState.formData}
            entityKey={entityKey}
            entityId={entityId}
            mode={formMode}
          />
        )}
      </FormProvider>
      {portalElement}
      {modals}
    </div>
  );
};

EntityForm.propTypes = {
  entityState: PropTypes.any,
  formMode: PropTypes.any,
  entityActionBarRef: PropTypes.any,
  entityId: PropTypes.number,
  entityKey: PropTypes.string.isRequired,
};

EntityForm.defaultProps = {
  entityActionBarRef: undefined,
  entityId: undefined,
  entityState: undefined,
  formMode: undefined,
};

export default EntityForm;
