import { memo, useEffect } from 'react';

// import * as Lesri from 'esri-leaflet';
import PropTypes from 'prop-types';

const { L } = window;

// const overlayLayers = [
//   // {
//   //   name: 'Cadastre',
//   //   url:
//   //     'https://gisservices.information.qld.gov.au/arcgis/rest/services/PlanningCadastre/LandParcelPropertyFramework/MapServer',
//   //   opacity: 0.9,
//   //   minZoom: 14,
//   //   // layers: [10],
//   //   addToMap: false,
//   // },
//   // {
//   //   name: 'Roads',
//   //   url:
//   //     'https://gisservices.information.qld.gov.au/arcgis/rest/services/Transportation/Roads/MapServer',
//   //   opacity: 0.6,
//   //   minZoom: 8,
//   //   addToMap: true,
//   // },
// ];

const MapLayers = ({ map }) => {
  useEffect(() => {
    const overlayMaps = {};
    // overlayLayers.forEach(layer => {
    //   const defaultProps = {
    //     useCors: false,
    //   };

    //   const options = {
    //     ...defaultProps,
    //     ...layer,
    //   };
    //   const mapLayer = Lesri.dynamicMapLayer(options);
    //   if (layer.addToMap) {
    //     mapLayer.addTo(map);
    //   }
    //   overlayMaps[layer.name] = mapLayer;
    // });

    const street = L.tileLayer(
      'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}',
      {
        attribution:
          'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        maxZoom: 25,
        id: 'mapbox/streets-v11',
        tileSize: 512,
        zoomOffset: -1,
        accessToken:
          'pk.eyJ1IjoiZGFya2hhbmJhYXRhciIsImEiOiJja3l0a3RvdjcxZXMzMzBqank4Zm5mMjhuIn0.rygqjNBlhTe0_lepUDcD3w',
      },
    );

    // const greyBaseMap = Lesri.tiledMapLayer({
    //   url:
    //     'https://gisservices.information.qld.gov.au/arcgis/rest/services/Basemaps/QldBase_Grey/MapServer',
    // });
    const baseMaps = {
      // 'Test': greyBaseMap,
      Street: street,
    };

    street.addTo(map);

    L.control
      .layers(baseMaps, overlayMaps, { position: 'bottomright' })
      .addTo(map);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  return <></>;
};

MapLayers.propTypes = {
  map: PropTypes.any.isRequired,
};

MapLayers.defaultProps = {};

export default memo(MapLayers);
